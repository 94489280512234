import dayjs from "dayjs";
import { Context } from "~/public_html/dfm5";

/**
 * Gets a set of user properties from the Context object
 * for use in PostHog events.
 *
 * @returns {{
 *  user_id: number | undefined,
 *  user_status: number | undefined,
 *  user_type: string,
 *  school_id: string | undefined,
 *  school_name: string | undefined
 * }}
 */
export function getUserPropertiesForEvent() {
  const user = Context?.user ?? null;
  const properties = user
    ? {
        user_id: user.uid,
        user_status: user.status,
        user_type: user.type,
        school_id: user.sid,
        school_name: user._school?.name ?? "",
      }
    : { user_type: "guest" };
  return properties;
}

/**
 * Builds a set of course properties for use in PostHog
 * events.
 *
 * @param {{
 *  authorName: string,
 *  coid: number,
 *  isPremade: boolean,
 *  name: string,
 *  type: string
 * }} course
 * @returns {{
 *  course_id: number,
 *  course_publisher: string,
 *  course_name: string,
 *  course_type: string,
 *  course_is_premade: boolean
 * }}
 */
export function getCoursePropertiesForEvent({
  authorName,
  coid,
  isPremade,
  name,
  type,
}) {
  return {
    course_id: coid,
    course_publisher: authorName,
    course_name: name,
    course_type: type,
    course_is_premade: isPremade,
  };
}

/**
 * Builds a set of course module properties for use in
 * PostHog events.
 *
 * @param {{ cmid: number, name: string, o: number }} module
 * @returns {{
 *  module_id: number,
 *  module_name: string,
 *  module_position: number
 * }}
 */
export function getCourseModulePropertiesForEvent({ cmid, name, o: order = 0 }) {
  return {
    module_id: cmid,
    module_name: name,
    module_position: order + 1,
  };
}

/**
 * Builds a set of course unit properties for use in PostHog
 * events.
 *
 * @param {{ cuid: number, name: string, o: number }} unit
 * @returns {{
 *  unit_id: number,
 *  unit_name: string,
 *  unit_position: number
 * }}
 */
export function getCourseUnitPropertiesForEvent({ cuid, name, o: order = 0 }) {
  return {
    unit_id: cuid,
    unit_name: name,
    unit_position: order + 1,
  };
}

/**
 * Builds a set of browse course filter properties for
 * use in PostHog events.
 *
 * @param {number} count The number of courses with current filters applied.
 * @param {{ text: string, publisher: string[], type: string[] }} filters The current filters applied.
 * @returns {{
 *  browse_courses_text_filter: string,
 *  browse_courses_publisher_filter: string | string[],
 *  browse_courses_type_filter: string | string[],
 *  browse_courses_count: number
 * }}
 */
export function getBrowseCoursesFilterPropertiesForEvent(count, filters = {}) {
  return {
    browse_courses_text_filter: filters.text ? filters.text : "n/a",
    browse_courses_publisher_filter: filters.publisher?.length ? filters.publisher : "n/a",
    browse_courses_type_filter: filters.type?.length ? filters.type : "n/a",
    browse_courses_list_count: count,
  };
}

/**
 * Builds a set of worksheet properties for use in
 * PostHog events.
 *
 * @param {Object} worksheet
 * @param {number | undefined} worksheet.wid
 * @param {number | undefined} worksheet.uid
 * @param {string | undefined} worksheet.name
 * @param {Array<{ qid: number | undefined, ssid: number | undefined  }> | undefined} worksheet.questions
 * @returns {{
 *  worksheet_id: number | undefined,
 *  worksheet_is_saved: boolean,
 *  worksheet_owner_id: number | undefined,
 *  worksheet_title: string | undefined,
 *  worksheet_question_type: string,
 *  worksheet_total_questions: number,
 *  worksheet_exam_questions: number,
 *  worksheet_generated_questions: number,
 *  worksheet_unselected_questions: number
 * }}
 */
export function getWorksheetPropertiesForEvent({ wid, uid, name, questions = [] }) {
  const totalQuestions = questions.length;
  const examQuestions = questions.filter(question => question.qid).length;
  const generatedQuestions = questions.filter(question => question.ssid).length;
  const unselectedQuestions = totalQuestions - examQuestions - generatedQuestions;
  let questionType;
  if (totalQuestions === 0 || totalQuestions === unselectedQuestions) {
    questionType = "empty";
  } else if (examQuestions > 0 && generatedQuestions > 0) {
    questionType = "mixed";
  } else if (examQuestions > 0) {
    questionType = "exam_only";
  } else {
    questionType = "generated_only";
  }
  return {
    worksheet_id: wid,
    worksheet_is_saved: !!wid,
    worksheet_owner_id: uid,
    worksheet_title: name,
    worksheet_question_type: questionType,
    worksheet_total_questions: totalQuestions,
    worksheet_exam_questions: examQuestions,
    worksheet_generated_questions: generatedQuestions,
    worksheet_unselected_questions: unselectedQuestions,
  };
}

/**
 * Builds a set of worksheet template properties for use
 * in PostHog events.
 *
 * @param {Object} template
 * @param {number | undefined} template.wtid
 * @param {number | undefined} template.uid
 * @param {string | undefined} template.name
 * @param {boolean} template.includeTypeRelatedProperties Whether to include properties relating to the template question type.
 * The type property is not returned by the API and the relevant client side methods can set it incorrectly as a result.
 * It's not worth tinkering with existing code so we can just set this flag to true when we can trust the type property.
 * @param {Array<{ ssid: number | undefined, type: string | undefined }> | undefined} template.questions
 * @returns {{
 *  worksheet_template_id: number | undefined,
 *  worksheet_template_is_saved: boolean,
 *  worksheet_template_owner_id: number | undefined,
 *  worksheet_template_title: string | undefined,
 *  worksheet_template_total_questions: number,
 *  worksheet_template_type: string | undefined,
 *  worksheet_template_exam_questions: number | undefined,
 *  worksheet_template_generated_questions: number | undefined,
 *  worksheet_template_unselected_questions: number | undefined
 * }}
 */
export function getWorksheetTemplatePropertiesForEvent({ wtid, uid, name, includeTypeRelatedProperties, questions = [] }) {
  const totalQuestions = questions.length;
  let typeRelatedProperties = {}
  if (includeTypeRelatedProperties) {
    const examQuestions = questions.filter(question => question.type === "e").length;
    const generatedQuestions = questions.filter(question => question.type === "k").length;
    const unselectedQuestions = totalQuestions - examQuestions - generatedQuestions;
    let questionType;
    if (totalQuestions === 0 || totalQuestions === unselectedQuestions) {
      questionType = "empty";
    } else if (examQuestions > 0 && generatedQuestions > 0) {
      questionType = "mixed";
    } else if (examQuestions > 0) {
      questionType = "exam_only";
    } else {
      questionType = "generated_only";
    }
    typeRelatedProperties = {
      worksheet_template_type: questionType,
      worksheet_template_exam_questions: examQuestions,
      worksheet_template_generated_questions: generatedQuestions,
      worksheet_template_unselected_questions: unselectedQuestions,
    }
  }
  return {
    worksheet_template_id: wtid,
    worksheet_template_is_saved: !!wtid,
    worksheet_template_owner_id: uid,
    worksheet_template_title: name,
    worksheet_template_total_questions: totalQuestions,
    ...typeRelatedProperties,
  };
}

/**
 * Builds a set of worksheet template question
 * properties for use in PostHog events.
 *
 * @param {Object} question
 * @param {number | undefined} question.ssid
 * @param {string | undefined} question.type
 * @param {string | undefined} question.difficulty
 * @param {boolean | undefined} question.calculator
 * @param {string | string[] | undefined} question.source
 * @returns {{
 *  worksheet_template_question_subskill_id: number | undefined,
 *  worksheet_template_question_difficulty: string | undefined,
 *  worksheet_template_question_calculator: boolean | undefined,
 *  worksheet_template_question_source: string | string[] | undefined,
 *  worksheet_template_question_subskill_type: string | undefined
 * }}
 */
export function getWorksheetTemplateQuestionPropertiesForEvent({
  ssid,
  type,
  difficulty,
  calculator,
  source,
}) {
  let typeRelatedProperties = {};
  if (type) {
    typeRelatedProperties = {
      worksheet_template_question_subskill_type: type === "e" ? "exam_practice" : "question_generator",
    };
  }
  return {
    worksheet_template_question_subskill_id: ssid,
    worksheet_template_question_difficulty: difficulty,
    worksheet_template_question_calculator: calculator,
    worksheet_template_question_source: source,
    ...typeRelatedProperties,
  };
}

/**
 * Builds a set of subskill properties for use in
 * PostHog events.
 *
 * @param {{ ssid: number, letter: string?, name: string, publicid: number, type: string }} subskill
 * @returns {{
 *  subskill_id: number,
 *  subskill_code: string,
 *  subskill_name: string,
 *  subskill_type: string
 * }}
 */
export function getSubskillPropertiesForEvent({ ssid, letter, name, publicid, type }) {
  let typeRelatedProperties = {};
  if (type) {
    typeRelatedProperties = {
      subskill_code: type === "e" ? publicid : `${publicid}${letter ?? ""}`,
      subskill_type: type === "e" ? "exam_practice" : "question_generator",
    };
  }
  return {
    subskill_id: ssid,
    subskill_name: name,
    ...typeRelatedProperties,
  };
}

/**
* Builds a set of exam question properties for use
* in PostHog events.
*
* @param {{ qid: number, authorName: string, qualificationName: string }} question
* @returns {{
*   exam_question_id: number,
*   exam_question_author_name: string,
*   exam_question_qualification_name: string
* }}
*/
export function getExamQuestionPropertiesForEvent({ qid, authorName, qualificationName }) {
return {
  exam_question_id: qid,
  exam_question_author_name: authorName,
  exam_question_qualification_name: qualificationName,
};
}

/**
* Builds a set of generated question properties for
* use in PostHog events.
*
* @param {{ subskill: Parameters<typeof getSubskillPropertiesForEvent>[0], params: string }} question
* @returns {{
*  generated_question_subskill_id: number,
*  generated_question_subskill_code: string,
*  generated_question_subskill_name: string,
*  generated_question_parameters: string
* }}
*/
export function getGeneratedQuestionPropertiesForEvent({ subskill = {}, params }) {
  const properties = {};
  if (subskill.ssid) {
    properties.generated_question_subskill_id = subskill.ssid;
  }
  if (subskill.publicid) {
    properties.generated_question_subskill_code = `${subskill.publicid}${subskill.letter ?? ""}`;
  }
  if (subskill.name) {
    properties.generated_question_subskill_name = subskill.name;
  }
  if (params) {
    properties.generated_question_parameters = JSON.stringify(params);
  }
  return properties;
}

/**
 * Builds a set of properties for the filters set
 * on the worksheet exam question modal for use in
 * PostHog events.
 *
 * @param {Object} filters
 * @param {{ skid: number, publicid: number, name: string } | undefined} filters.skill
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0] | undefined} filters.course
 * @param {string | string[] | undefined} filters.source
 * @param {number | undefined} filters.difficulty
 * @param {string | undefined} filters.keywords
 * @returns {{
 *  worksheet_exam_question_modal_filter_skill_id: number | undefined,
 *  worksheet_exam_question_modal_filter_skill_public_id: number | undefined,
 *  worksheet_exam_question_modal_filter_skill_name: string | undefined,
 *  worksheet_exam_question_modal_filter_course_id: number | undefined,
 *  worksheet_exam_question_modal_filter_course_publisher: string | undefined,
 *  worksheet_exam_question_modal_filter_course_name: string | undefined,
 *  worksheet_exam_question_modal_filter_course_type: string | undefined,
 *  worksheet_exam_question_modal_filter_course_is_premade: boolean | undefined,
 *  worksheet_exam_question_modal_filter_source: string | string[] | undefined,
 *  worksheet_exam_question_modal_filter_difficulty: number | undefined,
 *  worksheet_exam_question_modal_filter_keywords: string | undefined
 * }}
 */
export function getWorksheetExamQuestionModalFilterPropertiesForEvent({
  skill,
  course,
  source,
  difficulty,
  keywords,
}) {
  let properties = {};
  if (skill) {
    properties.worksheet_exam_question_modal_filter_skill_id = skill.skid;
    properties.worksheet_exam_question_modal_filter_skill_public_id = skill.publicid;
    properties.worksheet_exam_question_modal_filter_skill_name = skill.name;
  }
  if (course) {
    const courseProperties = getCoursePropertiesForEvent(course);
    const prefix = "worksheet_exam_question_modal_filter_skill_";
    const prefixedCourseProperties = Object.fromEntries(
      Object.entries(courseProperties).map(([key, value]) => [`${prefix}${key}`, value])
    );
    properties = { ...properties, ...prefixedCourseProperties };
  }
  if (source) {
    properties.worksheet_exam_question_modal_filter_source = source;
  }
  if (difficulty) {
    properties.worksheet_exam_question_modal_filter_difficulty = difficulty;
  }
  if (keywords) {
    properties.worksheet_exam_question_modal_filter_keywords = keywords;
  }
  return properties;
}

/**
 * A task has a lot of properties, use JSDoc to document them in sensible groups.
 *
 * @typedef {Object} BaseTaskProperties
 * @property {number} task_id
 * @property {number} task_creator_id
 * @property {string | undefined} task_label
 * @property {boolean} task_is_independent_practice
 * @property {number | undefined} task_number_of_questions
 * @property {number} task_number_of_subskills
 * @property {number | undefined} task_number_of_skills
 * @property {string} task_type_of_questions
 * @property {number | undefined} task_days_until_schedule_date
 * @property {number | undefined} task_days_until_due_date
 * @property {boolean | undefined} task_send_email_notifications
 *
 * @typedef {Object} FixedTaskProperties
 * @property {"fixed"} task_type
 * @property {number} task_fixed_worksheet_id
 * @property {string} task_fixed_worksheet_title
 * @property {number} task_fixed_worksheet_creator_id
 * @property {boolean} task_fixed_is_assessment
 *
 * @typedef {Object} FlexibleTaskProperties
 * @property {"flexible"} task_type
 * @property {string} task_flexible_completion_mode
 * @property {string | undefined} task_flexible_selection_mode
 * @property {string | undefined} task_flexible_accuracy_required_string
 * @property {number | undefined} task_flexible_accuracy_required_proportion
 * @property {string | undefined} task_flexible_exam_question_difficulty
 * @property {string | string[] | undefined} task_flexible_exam_question_source
 *
 * @typedef {Object} CleanupTaskProperties
 * @property {"cleanup"} task_type
 *
 * @typedef {Object} UnknownTypeProperties
 * @property {"unknown"} task_type
 *
 * @typedef { FixedTaskProperties | FlexibleTaskProperties | CleanupTaskProperties | UnknownTypeProperties } SpecificTaskProperties
 *
 * @typedef {Object} TaskSettingsProperties
 * @property {boolean | undefined} task_settings_warn_if_wrong
 * @property {boolean | undefined} task_settings_prevent_reattempts
 * @property {string | undefined} task_settings_require_working
 * @property {string | undefined} task_settings_feedback_required
 * @property {boolean | undefined} task_settings_use_exam_marking
 * @property {number | undefined} task_settings_time_limit
 */

/**
 * Builds a set of task properties for use in PostHog events.
 *
 * @param {Object} task
 * @param {number} task.aid
 * @param {number} task.creatorid
 * @param {string} task.type
 * @param {string | undefined} task.label
 * @param {number} task.setdate
 * @param {number | undefined} task.duedate
 * @param {boolean} task.toschedule
 * @param {boolean} task.emailNotifications
 * @param {string | string[] | undefined} task.examQuestionSource
 * @param {string | undefined} task.cmode // For flexible tasks
 * @param {Object} task.cdata
 * @param {number | undefined} task.cdata.accuracymeasure // For flexible tasks
 * @param {boolean | undefined} task.cdata.differentiate // For flexible tasks
 * @param {string | undefined} task.cdata.difficulty // For flexible tasks
 * @param {boolean | undefined} task.cdata.givewarning
 * @param {boolean | undefined} task.cdata.interleave // For flexible tasks
 * @param {number | undefined} task.cdata.numcorrect // For flexible tasks
 * @param {number | undefined} task.cdata.numquestions // For flexible tasks
 * @param {number | undefined} task.cdata.outof // For flexible tasks
 * @param {boolean | undefined} task.cdata.preventreattempts
 * @param {Object.<string, { ssid: number | undefined, qid: number | undefined }> | undefined} task.cdata.questions // For cleanup tasks
 * @param {number | undefined} task.cdata.requirefeedback
 * @param {number | undefined} task.cdata.requireworking
 * @param {number[] | undefined} task.cdata.skids // For flexible tasks
 * @param {number[] | undefined} task.cdata.ssids // For flexible tasks
 * @param {number | undefined} task.cdata.timelimit
 * @param {{ wid: number, name: string, uid: number, numquestions: number, mix: string } | undefined} task._worksheet // For fixed tasks
 * @returns {BaseTaskProperties & SpecificTaskProperties & TaskSettingsProperties}
 */
export function getTaskPropertiesForEvent(task) {
  const {
    aid,
    creatorid,
    type,
    label,
    setdate,
    duedate,
    toschedule,
    examQuestionSource,
    emailNotifications,
    cmode,
    _worksheet,
  } = task;

  const cdata = task.cdata ?? {};

  /**
   * First, let's work out if it's a fixed, flexible or cleanup task.
   *
   * We need to go through the task codes from the Task PHP class that can
   * still be set in the UI.
   *
   * We can also set whether or not the task is independent practice here.
   */
    let taskType = "unknown";
    let isIndependentPractice;
    switch (type) {
      case "c": // Independently started task from a worksheet or flexible task with exam questions only.
      case "t": // Teacher/parent set task from a worksheet or flexible task with exam questions only.
        taskType = _worksheet ? "fixed" : "flexible";
        isIndependentPractice = type === "c";
        break;
      case "kc": // Independently started flexible task.
      case "kt": // Teacher/parent set flexible task.
        taskType = "flexible";
        isIndependentPractice = type === "kc";
        break;
      case "a": // Set assessment style task from a worksheet.
        taskType = _worksheet ? "fixed" : taskType;
        isIndependentPractice = false;
        break;
      case "z": // Cleanup task.
        taskType = "cleanup";
        isIndependentPractice = true;
        break;
    }

  /**
   * Next, we can start building the three property objects that combine for
   * the final set of task properties to return.
   */
  let baseTaskProperties = {
    task_id: aid,
    task_creator_id: creatorid,
    task_is_independent_practice: isIndependentPractice,
    task_label: label == null ? undefined : label,
    task_days_until_schedule_date: toschedule && setdate ? dayjs(setdate * 1000).diff(dayjs(), "days") : undefined,
    task_days_until_due_date: duedate ? dayjs(duedate * 1000).diff(dayjs(), "days") : undefined,
    task_send_email_notifications: isIndependentPractice ? undefined : emailNotifications,
  };
  let specificTaskProperties = {
    task_type: taskType,
  };
  let taskSettingsProperties = {
    task_settings_warn_if_wrong: cdata.givewarning == null ? undefined : cdata.givewarning,
    task_settings_prevent_reattempts: cdata.preventreattempts == null ? undefined : cdata.preventreattempts,
    task_settings_require_working: { 0: "not_required", 1: "required", 2: "optional"}[cdata.requireworking],
    task_settings_feedback_required: { 0: "not_required", 1: "required_if_incorrect", 2: "always_required" }[cdata.requirefeedback],
    task_settings_use_exam_marking: cdata.accuracymeasure == null ? undefined : cdata.accuracymeasure === 1,
    task_settings_time_limit: cdata.timelimit ? cdata.timelimit / 60 : undefined,
  }

  /**
   * We can now include the remaining properties that are conditional on the task type.
   */
  if (taskType === "fixed") {
    // We know that _worksheet is defined if we have a "fixed" task_type set.
    const worksheet = _worksheet;

    baseTaskProperties = {
      ...baseTaskProperties,
      task_number_of_questions: worksheet.numquestions,
      task_type_of_questions: { "m": "mixed", "e": "exam_only", "k": "generated_only" }[worksheet.mix],
      task_number_of_subskills: (worksheet._subskills ?? []).length,
    }

    specificTaskProperties = {
      ...specificTaskProperties,
      task_type: "fixed",
      task_fixed_worksheet_id: worksheet.wid,
      task_fixed_worksheet_title: worksheet.name,
      task_fixed_worksheet_creator_id: worksheet.uid,
      task_fixed_is_assessment: type === "a",
    }
  }

  if (taskType === "flexible") {
    const typeOfQuestions = type === "c" || type === "t" ? "exam_only" : "generated_only";
    baseTaskProperties = {
      ...baseTaskProperties,
      task_number_of_questions: cdata.numquestions == null ? undefined : cdata.numquestions,
      task_number_of_subskills: (cdata.ssids ?? []).length,
      task_number_of_skills: (cdata.skids ?? []).length,
      task_type_of_questions: typeOfQuestions,
    }

    const completionMode = { "fixednum": "fixed_number", "accuracy": "accuracy", "nostop": "no_stop" }[cmode];
    let selectionMode;
    if (completionMode === "fixed_number") {
      selectionMode = cdata.differentiate ? "differentiate" : cdata.interleave ? "interleave" : "neither";
    }
    if (completionMode === "accuracy") {
      selectionMode = cdata.interleave ? "interleave" : "neither";
    }
    specificTaskProperties = {
      ...specificTaskProperties,
      task_type: "flexible",
      task_flexible_completion_mode: completionMode,
      task_flexible_selection_mode: selectionMode,
    }
    if (completionMode === "accuracy" && cdata.numcorrect && cdata.outof) {
      specificTaskProperties.task_flexible_accuracy_required_string = `${cdata.numcorrect} out of ${cdata.outof}`;
      specificTaskProperties.task_flexible_accuracy_required_proportion = Number((cdata.numcorrect / cdata.outof).toFixed(2));
    }
    if (typeOfQuestions === "exam_only") {
      specificTaskProperties.task_flexible_exam_question_difficulty = cdata.difficulty == null ? undefined : cdata.difficulty;
      specificTaskProperties.task_flexible_exam_question_source = examQuestionSource;
    }
  }

  if (specificTaskProperties.task_type === "cleanup") {
    const cleanupQuestions = typeof cdata.questions === "object" ? Object.values(cdata.questions) : [];
    const generatedQuestions = cleanupQuestions.filter(q => q.ssid);
    const examQuestions = cleanupQuestions.filter(q => q.qid);
    const uniqueSubskills = [...new Set(generatedQuestions.map(q => q.ssid))];
    let questionType;
    if (generatedQuestions.length && examQuestions.length) {
      questionType = "mixed";
    } else if (generatedQuestions.length) {
      questionType = "generated_only";
    } else if (examQuestions.length) {
      questionType = "exam_only";
    }
    baseTaskProperties = {
      ...baseTaskProperties,
      task_label: "Cleanup Task",
      task_number_of_questions: cleanupQuestions.length,
      task_type_of_questions: questionType,
      task_number_of_subskills: uniqueSubskills.length,
    }
  }

  return {
    ...baseTaskProperties,
    ...specificTaskProperties,
    ...taskSettingsProperties,
  };
}

/**
 * Builds a set of task attempt properties for use in
 * PostHog events.
 *
 * @param {Object} attempt
 * @param {number} attempt.aaid
 * @param {boolean} attempt.paused
 * @param {boolean} attempt.complete
 * @param {number} attempt.islate
 * @param {boolean} attempt.reattempt
 * @param {number[]} attempt.numcompleted // An array where the first element is the number of questions completed.
 * @param {number[]} attempt.numcorrect // An array where the first element is the number of questions answered correctly.
 * @param {number | undefined} taskNumberOfQuestions
 * @returns {{
 *  task_attempt_id: number,
 *  task_attempt_number_of_questions_answered: number,
 *  task_attempt_number_of_questions_correct: number,
 *  task_attempt_number_of_questions_incorrect: number,
 *  task_attempt_progress: number | undefined,
 *  task_attempt_accuracy: number | undefined,
 *  task_attempt_is_complete: boolean,
 *  task_attempt_is_late: boolean,
 *  task_attempt_is_paused: boolean,
 *  task_attempt_is_reattempt: boolean
* }}
*/
export function getTaskAttemptPropertiesForEvent(
 {
   aaid,
   paused,
   complete,
   islate,
   reattempt,
   numcompleted = [],
   numcorrect = [],
 },
 taskNumberOfQuestions,
) {
 const numberAnswered = numcompleted[0] ?? 0;
 const numberCorrect = numcorrect[0] ?? 0;
 const numberIncorrect = numberAnswered - numberCorrect;
 const progress = taskNumberOfQuestions ? Number(((numberAnswered ?? 0) / taskNumberOfQuestions).toFixed(2)) : undefined;
 const accuracy = numberAnswered ? Number((numberCorrect / numberAnswered).toFixed(2)) : undefined;
 return {
   task_attempt_id: aaid,
   task_attempt_number_of_questions_answered: numberAnswered,
   task_attempt_number_of_questions_correct: numberCorrect,
   task_attempt_number_of_questions_incorrect: numberIncorrect,
   task_attempt_progress: progress,
   task_attempt_accuracy: accuracy,
   task_attempt_is_complete: complete,
   task_attempt_is_late: islate === 1,
   task_attempt_is_paused: paused,
   task_attempt_is_reattempt: reattempt,
 };
}

/**
 * Builds a set of resource properties for use in
 * PostHog events.
 *
 * @param {Object} resource
 * @param {number} resource.rid
 * @param {string} resource.title
 * @returns {{
 *  resource_id: number,
 *  resource_title: string
 * }}
 */
export function getResourcePropertiesForEvent({ rid, title }) {
  return {
    resource_id: rid,
    resource_title: title,
  };
}

/**
 * Builds a set of resource file properties for use
 * in PostHog events.
 *
 * @param {Object} file
 * @param {number} file.fid
 * @param {string} file.filename
 * @param {string} file.type
 * @param {number} file.access
 * @returns {{
 *  resource_file_id: number,
 *  resource_file_path: string,
 *  resource_file_type: string,
 *  resource_file_teacher_only: boolean
 * }}
 */
export function getResourceFilePropertiesForEvent({
  fid,
  filename,
  type,
  access,
}) {
  return {
    resource_file_id: fid,
    resource_file_path: filename,
    resource_file_type: type,
    resource_file_teacher_only: access === 1,
  };
}

/**
 * Builds a set of live game properties for use in
 * PostHog events.
 *
 * @param {number} gid
 * @param {Object} options
 * @param {number} options.entrymode 0 = login (no nickname), 1 = login (nickname), 2 = guests, 3 = class mode
 * @param {number} options.pointsmode 0 = speed bonus, 1 = no speed bonus
 * @param {number} options.studentdisplay 0 = display answer, 1 = display question and answer
 * @param {number | undefined} options.wid
 * @param {{ ssid: number, type: string }[] | undefined} options.subskills
 * @param {number} options.numquestions
 * @returns {{
 *  live_game_id: number,
 *  live_game_number_of_questions: number
 *  live_game_login_required: boolean,
 *  live_game_nicknames_allowed: boolean,
 *  live_game_class_mode_used: boolean,
 *  live_game_speed_bonus_used: boolean,
 *  live_game_display_questions: boolean,
 *  live_game_using_worksheet: boolean,
 *  live_game_number_of_subskills: number | undefined,
 *  live_game_type_of_subskills: string | undefined,
 * }}
 */
export function getLiveGamePropertiesForEvent(gid, options) {
 const {
   entrymode,
   pointsmode,
   studentdisplay,
   wid,
   subskills,
   numquestions,
 } = options;
 let questionType;
 if (subskills) {
   const generatedSubskills = subskills.filter(
     (subskill) => subskill.type === "k",
   );
   const examSubskills = subskills.filter((subskill) => subskill.type === "e");
   if (generatedSubskills.length && examSubskills.length) {
     questionType = "mixed";
   } else if (generatedSubskills.length) {
     questionType = "generated_only";
   } else if (examSubskills.length) {
     questionType = "exam_only";
   }
 }
 return {
   live_game_id: gid,
   live_game_number_of_questions: numquestions,
   live_game_login_required: [0, 1, 3].includes(entrymode),
   live_game_nicknames_allowed: [1, 2].includes(entrymode),
   live_game_class_mode_used: entrymode === 3,
   live_game_speed_bonus_used: pointsmode === 0,
   live_game_display_questions: studentdisplay === 1,
   live_game_using_worksheet: !!wid,
   live_game_number_of_subskills: subskills ? subskills.length : undefined,
   live_game_type_of_subskills: questionType,
 };
}