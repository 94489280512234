import "mathquill/build/mathquill"
import "mathquill/build/mathquill.css"

let algebraDebug = false;
let numValTests = 20;

jQuery.fn.algebraicInput = function(options, options2) {
   var div = $(this);

   if(!options || options==="handlers") {
      var MQ = MathQuill.getInterface(2);
      var config = {
         spaceBehavesLikeTab: true,
         leftRightIntoCmdGoes: 'up',
         restrictMismatchedBrackets: true,
         sumStartsWithNEquals: true,
         supSubsRequireOperand: true,
         charsThatBreakOutOfSupSub: '=<>',
         substituteTextarea: function(){
           var apple = null!==navigator.userAgent.match(/(iPad|iPhone|iPod)/i);
            var android = null!==navigator.userAgent.match(/Android/i);
            if(apple||android) {
               return $("<span tabindex=0 style='display:inline-block;height:1px;width:1px'>")[0];
            } else {
               var elem = $("<textarea autocapitalize=off autocomplete=off autocorrect=off spellcheck=false x-palm-disable-ste-all=true>");
               elem.keydown(function(e){
                  // elem.prop("readonly",false);
                  elem.select();
                  div.algebraicInput("keystroke", "None");
               });
               elem.blur(function(){
                  // elem.prop("readonly",true);
                  closeKeyboard(true);
               });
               return elem[0];
            }
         },
         autoSubscriptNumerals: true,
         autoCommands: 'pi theta sqrt sum',
         autoOperatorNames: 'sin cos tan sec cot cosec csc sinh cosh tanh sech coth cosech arcsin arccos arctan ln log'
      }
      if(options==="handlers")config['handlers'] = options2;

      var mathField = MQ.MathField(div[0], config);
      div.data("mathField", mathField);

      div.click(function(){
         showKeyboard(div);
      });

   }
   else if(options==="latex" && !options2 && options2!=="") {
      // Get latex
      return div.data("mathField").latex();
   }
   else if(options==="latex" && (options2 || options2==="")) {
      // Set latex
      div.data("mathField").latex(options2);
   }
   else if(options==="reflow") {
      div.data("mathField").reflow();
   }
   else if(options==="focus") {
      div.data("mathField").focus();
   }
   else if(options==="insert") {
      div.data("mathField").write(options2)
   }
   else if(options==="command") {
      div.data("mathField").cmd(options2)
   }
   else if(options==="keystroke") {
      div.data("mathField").keystroke(options2)
   }
}

export function showKeyboard(div) {
   const bufferSpace = 400;

   $('body').css("padding-bottom", "0px");

   focusedAlg = div;
   if(div.offset().top + div.outerHeight() > $(document).height() - bufferSpace) {
      $('body').css("padding-bottom", (50 + div.offset().top + div.outerHeight() - ($(document).height() - bufferSpace))+"px");

   }
   $("#virtual-keyboard").fadeIn(200);
   $(window).resize();

   // If keyboard is covering input, scroll up.
   if(div.offset().top + div.outerHeight() - $(window).scrollTop() > $(window).height() - bufferSpace) {
      $(window).scrollTop(div.offset().top + div.outerHeight() - $(window).height() + bufferSpace + 20);
   }
}

export function closeKeyboard(instant) {
   if(!instant)$("#virtual-keyboard").fadeOut(200);
   else $("#virtual-keyboard").hide();
   $('body').css("padding-bottom","0px");
}

var keyboardButtons = {
   main : [
      {txt: "7", latex: "7"},
      {txt: "8", latex: "8"},
      {txt: "9", latex: "9"},
      {txt: "\\square/\\square", command: "\\frac"},
      {txt: "\\frac{\\square}{\\square}", command: "\\frac"},
      {txt: "4", latex: "4"},
      {txt: "5", latex: "5"},
      {txt: "6", latex: "6"},
      {txt: "\\times", latex: "\\times"},
      {txt: "1", latex: "1"},
      {txt: "2", latex: "2"},
      {txt: "3", latex: "3"},
      {txt: "-", latex: "-"},
      {txt: "0", latex: "0"},
      {txt: ".", latex: "."},
      {txt: "=", latex: "="},
      {txt: "+", latex: "+"}
   ],
   lessmain : [
      {txt: "x", latex: "x"},
      {txt: "y", latex: "y"},
      {txt: "e", latex: "e"},
      {txt: "\\pi", latex: "\\pi"},
      {txt: "\\sqrt{\\square}", command: "\\sqrt"},
      {txt: "\\sqrt[n]{\\square}", latex: "\\sqrt[n]{}"},
      {txt: "a^2", latex: "^2"},
      {txt: "a^\\square", command: "^"},
      {txt: "<", latex: "<"},
      {txt: ">", latex: ">"},
      {txt: "\\leq", latex: "\\leq"},
      {txt: "\\geq", latex: "\\geq"},
      {txt: "(", command: "("},
      {txt: ")", command: ")"},
      {txt: "!", latex: "!"},
      {txt: "\\theta", latex: "\\theta"}
   ],
   letters : [
      {txt: "a", latex: "a"},
      {txt: "b", latex: "b"},
      {txt: "c", latex: "c"},
      {txt: "d", latex: "d"},
      {txt: "e", latex: "e"},
      {txt: "f", latex: "f"},
      {txt: "g", latex: "g"},
      {txt: "h", latex: "h"},
      {txt: "i", latex: "i"},
      {txt: "j", latex: "j"},
      {txt: "k", latex: "k"},
      {txt: "l", latex: "l"},
      {txt: "m", latex: "m"},
      {txt: "n", latex: "n"},
      {txt: "o", latex: "o"},
      {txt: "p", latex: "p"},
      {txt: "q", latex: "q"},
      {txt: "r", latex: "r"},
      {txt: "s", latex: "s"},
      {txt: "t", latex: "t"},
      {txt: "u", latex: "u"},
      {txt: "v", latex: "v"},
      {txt: "w", latex: "w"},
      {txt: "x", latex: "x"},
      {txt: "y", latex: "y"},
      {txt: "z", latex: "z"},
      {txt: "\\alpha", latex: "\\alpha"},
      {txt: "\\delta", latex: "\\delta"},
      {txt: "\\theta", latex: "\\theta"},
      {txt: "\\lambda", latex: "\\lambda"},
      {txt: "\\mu", latex: "\\mu"},
      {txt: "\\omega", latex: "\\omega"}
   ],
   funcs : [
      {txt: "\\sin", latex: "\\sin"},
      {txt: "\\cos", latex: "\\cos"},
      {txt: "\\tan", latex: "\\tan"},
      {txt: "\\sin^{-1}", latex: "\\sin^{-1}"},
      {txt: "\\cos^{-1}", latex: "\\cos^{-1}"},
      {txt: "\\tan^{-1}", latex: "\\tan^{-1}"},
      {txt: "\\cosec", latex: "\\cosec"},
      {txt: "\\sec", latex: "\\sec"},
      {txt: "\\cot", latex: "\\cot"},
      {txt: "\\sinh", latex: "\\sinh"},
      {txt: "\\cosh", latex: "\\cosh"},
      {txt: "\\tanh", latex: "\\tanh"},
      {txt: "\\ln", latex: "\\ln"},
      {txt: "\\log_a", latex: "\\log_a"},
      {txt: "\\int", latex: "\\int"},
      {txt: "\\int^a_b", latex: "\\int^a_b"},
      {txt: "\\Sigma^a_b", latex: "\\Sigma^a_b"},
      {txt: "|\\square|", command: "|"}
   ],
   symbs : [
      {txt: "\\pm", latex: "\\pm"},
      {txt: "\\neq", latex: "\\neq"},
      {txt: "\\infty", latex: "\\infty"},
      {txt: "\\therefore", latex: "\\therefore"},
      {txt: "^{\\circ}", latex: "^{\\circ}"},
      {txt: "\\angle", latex: "\\angle"},
      {txt: "\\square", latex: "\\square"},
      {txt: "\\in", latex: "\\in"},
      {txt: "\\notin", latex: "\\notin"},
      {txt: "\\subset", latex: "\\subset"},
      {txt: "\\subseteq", latex: "\\subseteq"},
      {txt: "\\cap", latex: "\\cap"},
      {txt: "\\cup", latex: "\\cup"},
      {txt: "\\emptyset", latex: "\\empty"},
      {txt: "\\mathbb{N}", latex: "\\Naturals"},
      {txt: "\\mathbb{Z}", latex: "\\Integers"},
      {txt: "\\mathbb{Q}", latex: "\\Rationals"},
      {txt: "\\mathbb{R}", latex: "\\Reals"},
      {txt: "\\mathbb{C}", latex: "\\Complex"},
      {txt: "\\equiv", latex: "\\equiv"},
      {txt: "\\propto", latex: "\\propto"},
      {txt: "\\approx", latex: "\\approx"},
      {txt: "\\wedge", latex: "\\wedge"},
      {txt: "\\vee", latex: "\\vee"},
      {txt: "\\oplus", latex: "\\oplus"},
      {txt: "\\otimes", latex: "\\otimes"},
      {txt: ",", latex: ","}
   ]
};

var focusedAlg;
$(document).ready(function(){
   $("body").append("<div id='virtual-keyboard'><div id='keyboard-container'>"
   +"<div id='keyboard-main' class='keyboard'>"
          +"<div id='keyboard-button-section-lessmain' class='keyboard-button-section'></div>"
          +"<div id='keyboard-button-section-main' class='keyboard-button-section'></div>"
   +"</div>"
   +"<div id='keyboard-abc' class='keyboard'>"
          +"<div id='keyboard-button-section-letters' class='keyboard-button-section'></div>"
   +"</div>"
   +"<div id='keyboard-funcs' class='keyboard'>"
          +"<div id='keyboard-button-section-funcs' class='keyboard-button-section'></div>"
   +"</div>"
   +"<div id='keyboard-symbs' class='keyboard'>"
          +"<div id='keyboard-button-section-symbs' class='keyboard-button-section'></div>"
   +"</div>"
   +"<div id='keyboard-button-section-actions' class='keyboard-button-section'></div>"
   +"<div id='keyboard-button-section-tabs' class='keyboard-button-section'></div>"
   +"</div></div>");
   $(".keyboard").hide();


   $.each(keyboardButtons.main, function(k,b) {
      $("#keyboard-button-section-main").append("<span><button type='button'>$$"+b.txt+"$$</button></span>");
      if(b.txt==="\\square/\\square")$("#keyboard-button-section-main").children().last().attr('id', 'keyboard-frac-button');
      if(b.txt==="\\frac{\\square}{\\square}") {
         $("#keyboard-button-section-main").children().last().attr('id', 'keyboard-div-button');
      }
      $("#keyboard-button-section-main").children().last().click(function(){
         insertOrCommand(b); 
      });
   });
   $.each(keyboardButtons.lessmain, function(k,b) {
      $("#keyboard-button-section-lessmain").append("<span><button type='button'>$$"+b.txt+"$$</button></span>");
      $("#keyboard-button-section-lessmain").children().last().click(function(){
         insertOrCommand(b); 
      });
   });
   $.each(keyboardButtons.letters, function(k,b) {
      $("#keyboard-button-section-letters").append("<span><button type='button'>$$"+b.txt+"$$</button></span>");
      $("#keyboard-button-section-letters").children().last().click(function(){
         insertOrCommand(keyboardUpper ? {latex:b.latex.toUpperCase()} : b); 
      });
   });
   $.each(keyboardButtons.funcs, function(k,b) {
      $("#keyboard-button-section-funcs").append("<span><button type='button'>$$"+b.txt+"$$</button></span>");
      $("#keyboard-button-section-funcs").children().last().click(function(){
         insertOrCommand(b); 
      });
   });
   $.each(keyboardButtons.symbs, function(k,b) {
      $("#keyboard-button-section-symbs").append("<span><button type='button'>$$"+b.txt+"$$</button></span>");
      $("#keyboard-button-section-symbs").children().last().click(function(){
         insertOrCommand(b); 
      });
   });



   $("#keyboard-button-section-actions").append("<span><button type='button'><img src='/homework/img/backspace.png'></button></span>");
   $("#keyboard-button-section-actions").children().last().click(function(){
      focusedAlg.algebraicInput("keystroke", "Backspace");
   });
   $("#keyboard-button-section-actions").append("<span><button type='button'><img src='/homework/img/left.png'></button></span>");
   $("#keyboard-button-section-actions").children().last().click(function(){
      focusedAlg.algebraicInput("keystroke", "Left");
   });
   $("#keyboard-button-section-actions").append("<span><button type='button'><img src='/homework/img/right.png'></button></span>");
   $("#keyboard-button-section-actions").children().last().click(function(){
      focusedAlg.algebraicInput("keystroke", "Right");
   });
   $("#keyboard-button-section-actions").append("<span><button type='button'><img src='/homework/img/enter.png'></button></span>");
   $("#keyboard-button-section-actions").children().last().click(function(){
      focusedAlg.algebraicInput("keystroke", "Enter");
      closeKeyboard();
   });

   $("#keyboard-button-section-letters").prepend("<span><button type='button'><img src='/homework/img/ShiftKey.png'></button></span>");
   $("#keyboard-button-section-letters").children().first().click(function(){
      reverseLetterCase();
   });


   $("#keyboard-button-section-tabs").append("<span><button type='button' id='button-main-tab'>Main</button></span>");
   $("#button-main-tab").click(function(){
      $(".keyboard").hide();
      $("#keyboard-button-section-tabs button").removeClass("selected");
      $("#button-main-tab").addClass("selected");
      $("#keyboard-main").show();
   });
   $("#keyboard-button-section-tabs").append("<span><button type='button' id='button-abc-tab'>ABC</button></span>");
   $("#button-abc-tab").click(function(){
      $(".keyboard").hide();
      $("#keyboard-abc").show();
      $("#keyboard-button-section-tabs button").removeClass("selected");
      $("#button-abc-tab").addClass("selected");
   });
   $("#button-main-tab").click();
   $("#keyboard-button-section-tabs").append("<span><button type='button' id='button-funcs-tab'>Funcs</button></span>");
   $("#button-funcs-tab").click(function(){
      $(".keyboard").hide();
      $("#keyboard-funcs").show();
      $("#keyboard-button-section-tabs button").removeClass("selected");
      $("#button-funcs-tab").addClass("selected");
   });
   $("#keyboard-button-section-tabs").append("<span><button type='button' id='button-symbs-tab'>Symbs</button></span>");
   $("#button-symbs-tab").click(function(){
      $(".keyboard").hide();
      $("#keyboard-symbs").show();
      $("#keyboard-button-section-tabs button").removeClass("selected");
      $("#button-symbs-tab").addClass("selected");
   });

   var k = $("#virtual-keyboard");
   k.mousedown(preserveFocus);
   $(window).resize();

   $(document).click(function(e){
      if (!$(e.target).closest('#virtual-keyboard').length && !$(e.target).closest('.mq-editable-field').length) {
         closeKeyboard();
      }
   });


});

var keyboardUpper = false;
function reverseLetterCase() {
   keyboardUpper = !keyboardUpper;
   $("#keyboard-button-section-letters .mjx-char").each(function(){
      var letter = $(this).html();
      if(letter.match(/^[A-Za-z]$/)) {  // is single normal letter?
      // if(letter.length==1) {
         $(this).html(keyboardUpper ? letter.toUpperCase() : letter.toLowerCase());
      }
   });
}

function insertOrCommand(b) {
   if(b.latex)focusedAlg.algebraicInput("insert", b.latex);
   else focusedAlg.algebraicInput("command", b.command);
}

function preserveFocus(e) {
   e.preventDefault();
   e.stopPropagation();
}

$(window).resize(function(){
   var k = $("#virtual-keyboard");
   if(k.is(":visible")) {
      k.css("top", ($(window).height() - k.outerHeight())+"px");
      // k.offset({top: $(window).height() - k.outerHeight(), left: 0});
      k.outerWidth($(window).width());
   } else {
      k.css("top", ($(window).height() - 200)+"px");
      k.outerWidth($(window).width());
   }
});
