import { dfmAlert } from "~/public_html/dfm5";

const errorMessageLookup = {
  "COURSE_ACCESS_DENIED": "You don't have access to this course.",
  "COURSE_DELETED": "The course you're looking for has been deleted.",
  "COURSE_NOT_FOUND": "The course you're looking for doesn't exist.",
  "QGEN_FAILURE": "Our question generator failed to generate a question for you.",
  "NO_QUESTION_GENERATOR": "We don't have a question generator available for this subskill.",
  "ERROR_SAVING_WORKSHEET": "We were unable to save the worksheet. Please try again in a few moments.",
  "ERROR_MOVING_WORKSHEET": "We were unable to move the worksheet. Please try again in a few moments.",
  "ERROR_DELETING_WORKSHEET": "We were unable to delete the worksheet. Please try again in a few moments.",
};

const statusCodeLookup = {
  "503": "<strong>503:</strong> The service was unavailable. Please try again.",
  "504": "<strong>504:</strong> A server timeout occurred. Please try again.",
};

/**
 * Takes an API returned error message and returns a user-friendly
 * error message if a match is found.
 *
 * @param {string} apiErrorMessage
 * @returns {?string}
 */
export const getReadFriendlyErrorMessage = (apiErrorMessage) => errorMessageLookup[apiErrorMessage] ?? null;

/**
 * Takes a HTTP status code and returns a user-friendly
 * error message if a match is found.
 *
 * @param {string} statusCode
 * @returns {?string}
 */
export const getReadFriendlyStatusMessage = (statusCode) => statusCodeLookup[statusCode] ?? null;

/**
 * Returns a styled HTML string with an error message and additional
 * information.
 *
 * @param {?string} readFriendlyErrorMessage
 * @param {?string} additionalInformation
 * @returns {string}
 */
export function buildErrorMessageHtml(readFriendlyErrorMessage, additionalInformation) {
  return (
    `<div style="display: flex; flex-direction: column; gap: 6px; text-align: left;">
        <div style="align-items: center; display: flex; gap: 4px;">
            <img alt="error icon" src="/images/notification_late.svg" style="width: 16px;"/>
            <h4 style="margin: 0;">Oops! Something went wrong.</h4>
        </div>
        ${readFriendlyErrorMessage ? `<p style="margin: 0;">${readFriendlyErrorMessage}</p>` : ""}
        ${additionalInformation ? `<p style="color: gray; font-size: 12px; margin: 0; margin-top: 6px;">${additionalInformation}</p>` : ""}
    </div>`
  );
}

/**
 * Returns a styled HTML string with an error message and additional
 * information generated from a jQuery AJAX error.
 *
 * @param {JQuery.jqXHR} jqXHR
 * @param {string} [backupMessage]
 * @returns {string}
 */
export function getErrorMessageHtmlForAjaxError(jqXHR, backupMessage = "An unknown error occurred.") {
  if (typeof jqXHR !== 'object') {
    return buildErrorMessageHtml(backupMessage, null);
  }
  const apiErrorMessage = jqXHR.responseJSON?.message;
  const readFriendlyErrorMessage = getReadFriendlyErrorMessage(apiErrorMessage) ?? backupMessage;
  const additionalInformation = getReadFriendlyStatusMessage(jqXHR.status) ?? jqXHR.responseText ?? null;
  return buildErrorMessageHtml(readFriendlyErrorMessage, additionalInformation);
}

/**
 * Default error handler for a read friendly error message.
 * Presents the error message in a dfmAlert modal.
 *
 * @param {string} readFriendlyErrorMessage
 */
export function defaultErrorHandler(readFriendlyErrorMessage) {
  dfmAlert(
    `<div style="margin: 12px 0">${buildErrorMessageHtml(readFriendlyErrorMessage, null)}</div>`,
  );
}
