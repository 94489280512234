import { defaultErrorHandler, getReadFriendlyErrorMessage } from "./errors";

/**
 * Fetches a course by its id.
 * Uses default error handling.
 *
 * @param {number} coid
 * @returns {Promise<Object>}
 */
export async function getCourse(coid) {
  try {
    const response = await fetch(`/api/course/course/${coid}`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const course = await response.json();
    return course;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving this course.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches a course unit by its id.
 * Uses default error handling.
 *
 * @param {number} cuid
 * @param {boolean} includeInactiveSubskills
 * @returns {Promise<Object>}
 */
export async function getUnit(cuid, includeInactiveSubskills = true) {
  try {
    const url = includeInactiveSubskills ? `/api/course/explore/${cuid}` : `/api/course/unit/${cuid}`
    const response = await fetch(url);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const unit = await response.json();
    return unit;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving this course unit.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches basic details of the 'All Skills' course.
 * Uses default error handling.
 *
 * @returns {Promise<Object>}
 */
export async function getAllSkillsCourseDetails() {
  try {
    const response = await fetch(`/api/course/course/allskills/details`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const course = await response.json();
    return course;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving the All Skills course.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches an array of unique courses assigned to a user.
 * Uses default error handling.
 *
 * @param {number} uid
 * @returns {Promise<Object[]>}
 */
export async function getUserCourses(uid) {
  try {
    const response = await fetch(`/api/user/${uid}/courses`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    /**
     * This API endpoint returns a course for each class allocation.
     * Therefore it's possible for a user to belong to multiple classes
     * that have the same course allocated. As we just want to return
     * a list of unique courses, we'll filter out any duplicates.
     */
    return courses.reduce((acc, course) => {
      if (!acc.some((c) => c.coid === course.coid)) {
        acc.push(course);
      }
      return acc;
    }, []);
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving your user courses.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches an array of the courses assigned to each class a user belongs to.
 * Uses default error handling.
 *
 * @param {number} uid
 * @returns {Promise<Object[]>}
 */
export async function getUserCoursesByClass(uid) {
  try {
    const response = await fetch(`/api/user/${uid}/courses`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    return courses.reduce((acc, course) => {
      const { classes = [], ...rest } = course;
      const courseClassName = classes[0];
      if (courseClassName) {
        const existingClass = acc.find((cl) => cl.className === courseClassName);
        if (existingClass) {
          existingClass.courses.push(rest);
        } else {
          acc.push({ className: courseClassName, courses: [rest] });
        }
      }
      return acc;
    }, []);
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving your user courses by class.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches an array of the courses assigned to a school.
 * Accepts a custom error handler, falling back to the default.
 *
 * @param {Object} options
 * @param {boolean} options.getClasses
 * @param {number} options.sid
 * @param {(string) => void} [onError]
 * @returns {Promise<Object[]>}
 */
export async function getSchoolCourses({ getClasses = false, sid }, onError = defaultErrorHandler) {
  try {
    const response = await fetch("/api/course/school/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ classes: getClasses, sid }),
    });
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    return courses;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving your school courses.";
    if (typeof onError === "function") {
      onError(readFriendlyErrorMessage);
    }
  }
}

/**
 * Fetches an array of the courses assigned to a trust.
 * Accepts a custom error handler, falling back to the default.
 *
 * @param {Object} options
 * @param {number} options.acid
 * @param {(string) => void} [onError]
 * @returns {Promise<Object[]>}
 */
export async function getTrustCourses({ acid }, onError = defaultErrorHandler) {
  try {
    const response = await fetch("/api/course/trust/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ acid }),
    });
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    return courses;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving your trust courses.";
    if (typeof onError === "function") {
      onError(readFriendlyErrorMessage);
    }
  }
}

/**
 * Fetches the full published course selection for a user, organised by author.
 * Uses default error handling.
 *
 * @returns {Promise<Object[]>}
 */
export async function getAllPublishedCourses() {
  try {
    const response = await fetch("/api/courses/published/all");
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const authors = await response.json();
    return authors;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving the published courses.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches a specified selection of published courses.
 * Uses default error handling.
 *
 * @param {number[]} coids
 * @returns {Promise<Object[]>}
 */
export async function getPublishedCourses(coids) {
  try {
    const params = new URLSearchParams({ coids: coids.join(",") });
    const response = await fetch(`/api/courses/published/selection?${params.toString()}`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const courses = await response.json();
    return courses;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving the selection of published courses.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches the resource count for each unit in a course.
 * Uses default error handling.
 *
 * @param {number} coid
 * @returns {Promise<Object>}
 */
export async function getResourceCountForCourse(coid) {
  try {
    const response = await fetch(`/api/course/course/${coid}/resourcecount`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const counts = await response.json();
    return counts;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving the resource count for this course.";
    defaultErrorHandler(readFriendlyErrorMessage);
  }
}

/**
 * Fetches the relevant data and composes the courses for
 * the user's courses dropdown menu.
 *
 * The courses are organised and returned in groups:
 * @typedef {Object} CourseGroup
 * @property {string} id
 * @property {string} heading
 * @property {Object[]} courses
 *
 * @param {Object} options
 * @param {Object} options.user
 * @param {Object} options.preselectedCourse
 * @returns {Promise<CourseGroup[]>}
 */
export async function getCourseGroupsForDropdown({
  user = null,
  preselectedCourse = null,
} = {}) {
  // If there is a logged in user, get their selection of courses.
  const uid = user?.uid;
  const userCourses = uid ? (await getUserCourses(uid) ?? []) : [];

  // If they belong to a school, get the school selection of active courses, without duplicates of above.
  const sid = user?.sid;
  const schoolCourses = sid ? (await getSchoolCourses({ sid }) ?? []) : [];
  const schoolCoursesFiltered = schoolCourses.filter((course) => {
    const inUserCourses = userCourses.some(
      (c) => c.coid === course.coid,
    );
    const isActive = course.status === 1 || course.status === 3;
    return !inUserCourses && isActive;
  }) ?? [];

  // Get the 'All Skills' course that will display for all users, unless it's already in the user or school courses.
  let globalCourses = []
  const allSkillsCourseInUserCourses = userCourses.some(course => course.type === "ALL_SKILLS");
  const allSkillsCourseInSchoolCourses = schoolCoursesFiltered.some(course => course.type === "ALL_SKILLS");
  if (!allSkillsCourseInUserCourses && !allSkillsCourseInSchoolCourses) {
    const allSkillsCourse = await getAllSkillsCourseDetails();
    globalCourses = allSkillsCourse ? [allSkillsCourse] : [];
  }

  // If a preselected course has been passed to this function, check it isn't already included above.
  const allCoursesSoFar = [
    ...userCourses,
    ...schoolCoursesFiltered,
    ...globalCourses,
  ];
  const currentlyViewingCourse =
    preselectedCourse &&
    !allCoursesSoFar.some((c) => c.coid === preselectedCourse.coid)
      ? preselectedCourse
      : null;

  // Set up the course groups for the dropdown menu.
  const courseGroups = [
    {
      id: "current",
      heading: "Currently viewing",
      courses: currentlyViewingCourse ? [currentlyViewingCourse] : [],
    },
    {
      id: "user",
      heading: "Your courses",
      courses: userCourses,
    },
    {
      id: "school",
      heading: "School courses",
      courses: schoolCoursesFiltered,
    },
    {
      id: "all",
      heading: "All skills",
      courses: globalCourses,
    },
  ];

  return courseGroups;
}

/**
 * Retrieves the recently viewed course ids for a user
 * from local storage.
 *
 * @typedef {Object} Course
 * @property {number} coid
 * @property {string} authorName
 * @property {string} name
 * @property {number | undefined} numberOfModules
 * @property {boolean | undefined} isPremade
 * @property {string | undefined} thumb
 * @property {string} type
 *
 * @param {number} uid
 * @returns {Course[]}
 */
export function getRecentlyViewedCourses(uid) {
  const key = `recentlyViewedCourses_${uid}`;
  const courses = JSON.parse(localStorage.getItem(key)) || [];
  return courses
    .filter(course => course.coid && course.authorName && course.name && course.type)
    .map(({ coid, authorName, name, numberOfModules, isPremade, thumb, type }) => ({ coid, authorName, name, numberOfModules, isPremade, thumb, type }))
    .slice(0, 3);
}

/**
 * Sets a course id as the most recently viewed course
 * for a user in local storage. Keeps a maximum of 3
 * entries.
 *
 * @param {number} uid
 * @param {Course} course
 */
export function updateRecentlyViewedCourses(uid, course) {
  const key = `recentlyViewedCourses_${uid}`;
  const courseObject = {
    coid: course.coid,
    authorName: course.authorName,
    name: course.name,
    type: course.type,
  };
  if (course.thumb) {
    courseObject.thumb = course.thumb;
  }
  if (course.numberOfModules) {
    courseObject.numberOfModules = course.numberOfModules;
  }
  if (typeof course.isPremade === "boolean") {
    courseObject.isPremade = course.isPremade;
  }
  let courses = getRecentlyViewedCourses(uid);
  courses = courses.filter(course => course.coid !== courseObject.coid);
  courses.unshift(courseObject);
  courses = courses.slice(0, 3);
  localStorage.setItem(key, JSON.stringify(courses));
}

/**
 * Allocates a course to a school.
 * Does not include error handling.
 *
 * @param {number} coid
 * @param {number} sid
 * @returns {Promise<void>}
 */
export async function allocateCourseToSchool(coid, sid) {
  const response = await fetch("/api/course/allocate/school", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ coid, sid }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Unallocates a course from a school.
 * Does not include error handling.
 *
 * @param {number} coid
 * @param {number} sid
 * @returns {Promise<void>}
 */
export async function unallocateCourseFromSchool(coid, sid) {
  const response = await fetch("/api/course/unallocate/school", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ coid, sid }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Publishes a course.
 * Does not include error handling.
 *
 * @param {number} coid
 * @returns {Promise<void>}
 */
export async function publishCourse(coid) {
  const response = await fetch("/api/course/course", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ coid, status: 1 }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Unpublishes a course.
 * Does not include error handling.
 *
 * @param {number} coid
 * @returns {Promise<void>}
 */
export async function unpublishCourse(coid) {
  const response = await fetch("/api/course/course", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ coid, status: 2 }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Creates a brand new school course.
 * Does not include error handling.
 *
 * @param {string} name
 * @param {number} sid
 * @returns {Promise<void>}
 */
export async function addSchoolCourse(name, sid) {
  const response = await fetch("/api/course/course", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, sid }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  const course = await response.json();
  return course;
}

/**
 * Creates a brand new trust course.
 * Does not include error handling.
 *
 * @param {string} name
 * @param {number} acid
 * @returns {Promise<void>}
 */
export async function addTrustCourse(name, acid) {
  const response = await fetch("/api/course/course", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, acid }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  const course = await response.json();
  return course;
}

/**
 * Deletes a course.
 * Does not include error handling.
 *
 * @param {number} coid
 * @returns {Promise<void>}
 */
export async function deleteCourse(coid) {
  const response = await fetch(`/api/course/course/${coid}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Assigns a course to classes.
 * Does not include error handling.
 *
 * @param {number} coid
 * @param {number[]} cids
 * @returns {Promise<void>}
 */
export async function assignCourseToClasses(coid, cids) {
  const response = await fetch("/api/course/allocate/classes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ coid, cids }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Unassigns a course from classes.
 * Does not include error handling.
 *
 * @param {number} coid
 * @param {number[]} cids
 * @returns {Promise<void>}
 */
export async function unassignCourseFromClasses(coid, cids) {
  const response = await fetch("/api/course/unallocate/classes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ coid, cids }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Copies an existing course as a draft for either a school or trust.
 * Does not include error handling.
 *
 * @param {number} coid
 * @param {"school" | "trust"} entity
 * @returns {Promise<number>} The new course ID.
 */
export async function cloneCourse(coid, entity = "school") {
  const response = await fetch(`/api/course/clone/${coid}/${entity}`, {
    method: "POST",
  });
  /**
   * For cloning a course we want to handle the specific case
   * of a timeout differently.
   */
  if (response.status === 504) {
    throw new Error("TIMEOUT");
  }
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  const newCoid = await response.json();
  return newCoid;
}

/**
 * Updates the order of courses for a school.
 * Does not include error handling.
 *
 * @param {number} sid
 * @param {number[]} coids
 * @returns {Promise<void>}
 */
export async function updateSchoolCourseOrder(sid, coids) {
  const response = await fetch("/api/course/order", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ sid, order: coids }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}

/**
 * Updates the order of courses for a trust.
 * Does not include error handling.
 *
 * @param {number} acid
 * @param {number[]} coids
 * @returns {Promise<void>}
 */
export async function updateTrustCourseOrder(acid, coids) {
  const response = await fetch("/api/course/order", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ acid, order: coids }),
  });
  if (!response.ok) {
    const e = await response.json();
    throw new Error(e.message);
  }
  return;
}
