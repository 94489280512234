// Loaded from CDN and included in externals in webpack.config.js
import MathJax from "mathjax";

// Required JS from node_modules
import introJs from "intro.js";

// Required JS from local files
import {
   Context,
   dfmAlert,
   dfmDialog,
} from './dfm5';
import { distanceFromPointToLineSegment } from "./dfm-canvas-2.4";

// CSS
import "intro.js/introjs.css";

/* Parameters:
     control: true/false	- Mouse control?
     admin: true/false
     transmit: func
     teacher: true/false	- Has access to question directory.
     master: true/false		- Is master whiteboard? Will have eye icon in menu.
   Methods:
     setControl: true/false
     instantiateMenu: true/false

*/ 

var bWidth = 0;

jQuery.fn.dfmWhiteboard = function(arg1, arg2) {
   if(arg2===undefined || arg2===null) {
      // Constructor
      console.log("[WhiteboardConstruct] "+$(this).attr('id'));
      var canvas = $(this);
      canvas.addClass('dfmWhiteboard');
      var options = {};
      options.admin = arg1 && arg1.admin ? arg1.admin : false;
      options.control = arg1 && arg1.control ? arg1.control : false;
      options.master = arg1 && arg1.master ? arg1.master : false;
      options.teacher = arg1 && arg1.teacher ? arg1.teacher : false;	
      options.resize = arg1 && arg1.resize ? arg1.resize : false;
      options.crop = arg1 && arg1.crop ? arg1.crop : false;
      options.allowImageUpload = true; if(arg1 && (arg1.allowImageUpload===true || arg1.allowImageUpload===false))options.allowImageUpload = arg1.allowImageUpload;
      options.allowColorInvert = arg1 && arg1.allowColorInvert ? arg1.allowColorInvert : false;
      options.fixTextSize = arg1 && arg1.fixTextSize ? arg1.fixTextSize : false;	
      canvas.data('options', options);
console.log("Set options to "+JSON.stringify(options));
      canvas.data('core', {width: canvas.attr('width'), height: canvas.attr('height'), strokes: [], teacherStrokes: [], grid: "dots", });
      canvas.data('control', {zoom: 1.0, offset: {x: 0, y: 0}, colorInvert: false, currentStroke: undefined, currentTextBox: undefined, transmit: arg1 && arg1.transmit ? arg1.transmit : null, drawMode: 'paint', currentColour: "#000", isStroking: false, touchedStart1: null, touchedStart2: null, touchZoomStart: undefined});
      if(options.control)$(this).dfmWhiteboard("giveControl", true);

      if(arg1 && arg1.core) {
         if(arg1.uncompress)canvas.data('core').strokes = uncompressStrokes(arg1.core.strokes);
         if(arg1.crop) {
            const cropping = cropWhiteboard(canvas, canvas.data('core'));
            canvas.data('core', cropping.whiteboard);
            canvas.data('core').height = cropping.height * canvas.data('core').width;
            canvas.attr('height', canvas.data('core').height);
         }
         if(arg1.core.grid)canvas.data("core").grid = arg1.core.grid;
         if(arg1.core.img) {
            setWhiteboardImg(canvas, arg1.core.img, arg1.core.imgScale);
         }

         var equationStrokeCount = 0;  // 
         for(var i=0; i<canvas.data('core').strokes.length; i++) {
            var stroke = canvas.data('core').strokes[i];
            if(stroke.type == "equation") {
               equationStrokeCount++;
               loadEquationStrokeImage(stroke, function(){
                  equationStrokeCount--;
                  if(equationStrokeCount==0)drawWhiteboard(canvas);
               });
            }
         }

         if(equationStrokeCount==0)drawWhiteboard(canvas);
      }
      // console.log("Options: "+JSON.stringify(options));
      return;
   }

   var canvas = $(this);
   var wb = $(this).data("core");
   var control = $(this).data("control");
   var options = $(this).data("options");
   if(arg1==="giveControl") {
      if(arg2) {
         $(this).addClass('control');
         $(this).mousedown(function(e){ startDraw(canvas, e) });
         $(this).mousemove(function(e){ continueDraw(canvas, e) });
         $(this).mouseup(function(e){ endDraw(canvas, e) });
         $(this).mouseout(function(e){ if($(this).data("control").currentStroke)endDraw($(this), e) });
         $(this)[0].addEventListener('touchstart', function(e){ startDraw(canvas, e) });
         $(this)[0].addEventListener('touchmove', function(e){ continueDraw(canvas, e) });
         $(this)[0].addEventListener('touchend', function(e){ endDraw(canvas, e) });
         $(this)[0].addEventListener("mousewheel", function(e) {
            e.preventDefault();
            if(!control.zoom)control.zoom = 1.0;
            var zooming = e.ctrlKey; // if false, panning instead.
            var zoomingIn = e.wheelDelta>=1; // will be -120 if zooming out. -1 for trackpad.
            var trackpad = Math.abs(e.wheelDelta)<=40;
            var horizontal = e.wheelDeltaX!=undefined && e.wheelDeltaX != 0;
            if(zooming) {
               var p = calculatePoint(e, canvas, null, true);
               var oldZoom = control.zoom;
               if(zoomingIn) {
                  control.zoom+= control.zoom<1 ? 0.03 : 0.1;
               } else {
                  control.zoom-= control.zoom<1 ? 0.03 : 0.1;
                  if(control.zoom<0.3)control.zoom = 0.3;
               }
               var zoomSF = oldZoom / control.zoom;

               // console.log(JSON.stringify(p)+" "+JSON.stringify(pO) + " zoomSF = "+control.zoom);
               control.offset.x = p.x - (p.x - control.offset.x)*zoomSF;
               control.offset.y = p.y - (p.y - control.offset.y)*zoomSF;
            } else {
               console.log(JSON.stringify(e.wheelDelta+" "+e.wheelDeltaX+" "+e.wheelDeltaY));
               if(zoomingIn) {
                  if(!horizontal)control.offset.y-= 0.020 / (control.zoom * trackpad?10:1);
                  else control.offset.x-= 0.025 / (control.zoom * trackpad?10:1);
               } else {
                  if(!horizontal)control.offset.y+= 0.020 / (control.zoom * trackpad?10:1); 
                  else control.offset.x+= 0.025 / (control.zoom * trackpad?10:1); 
               }
            }
            drawWhiteboard(canvas);
         });

      } else {
         $(this).removeClass('control');
         $(this).css('cursor', 'default');
         $(this).unbind();
         $(document).unbind("keydown"); $(document).unbind("keypress") // text
      }
   }
   else if(arg1==="instantiateMenu" || arg1==="reinstantiateMenu") {
      var menu = arg2; 
      canvas.data("menu", menu);
      console.log(menu.attr('id'));

      var html = "<div class='colours'><ul>";
      var colours = ["#000", "#ff0", "#00f", "#0f0", "#aaa", "#f00", "#f80", "#a0f"];
      for(var i=0; i<colours.length; i++) {
         html+= "<li><a style='background-color:"+colours[i]+"' rel='"+colours[i]+"' href='#' "+(i==0 ? "class='colour-default'" : "")+"></a></li>";
      }
      html+= "</ul></div>";
      html+= "<div class='buttons'>";
      if(options.master) {
         html+= "<button class='paint-button button-tab'><img src='/homework/img/whiteboard-64.png' style='width:24px;height:24px' name='Paint'></button>";
         html+= "<button class='view-button button-tab'><img src='/legacy-assets/whiteboard/view.png' name='View Student Whiteboards'><span class='notification-number' id='connected-number'>0</span></button>";
      }
      html+= "<button class='pen-button mode-button'><img src='/images/draw_icon.svg'></button>";
      html+= "<button class='straightline-button mode-button'><img src='/legacy-assets/whiteboard/straightline.png'></button>";
      html+= "<button class='circle-button mode-button'><img src='/images/circle_icon.svg'></button>";
      html+= "<button class='arc-button mode-button'><img src='/legacy-assets/whiteboard/arc.png'></button>";
         
      html+= "<span class='erase-button-super button-menu'>";
      html+= "<button class='erase-button mode-button'><img src='/images/eraser_icon.svg' name='Erase'></button>";
      html+= "<ul class='erase-menu'>";
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/rubber.png'> Rubber</a></li>";   // "javascript:selectDrawMode('erase')"
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/bin.png'> Clear All</a></li>";   // javascript:clearStrokes()
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/bin.png'> Clear All (excl images)</a></li>";
      if(options.teacher)html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/bin.png'> Clear All for All</a></li>";
      html+= "</ul>";
      html+= "</span>";

      html+= "<span class='grid-button-super button-menu'>";
      html+= "<button class='grid-button'><img src='/legacy-assets/whiteboard/grid.png' name='Grids'></button>";
      html+= "<ul class='grid-menu'>";
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/grid-none.png'> None</a></li>"; // javascript:selectGrid('none')
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/grid-lines.png'> Grid Lines (L)</a></li>"; // javascript:selectGrid('grid')
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/grid-linessmall.png'> Grid Lines (S)</a></li>"; // javascript:selectGrid('gridsmall')
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/grid-dots.png'> Grid Dots</a></li>"; // javascript:selectGrid('dots')
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/grid-isometric.png'> Isometric (H)</a></li>"; // javascript:selectGrid('isometric')
      html+= "<li><a href='#'><img src='/legacy-assets/whiteboard/grid-isometric2.png'> Isometric (V)</a></li>"; // javascript:selectGrid('isometric2')
      html+= "</ul>";
      html+= "</span>";

      if(options.allowImageUpload) {
         html+= "<button class='uploadimage-button mode-button'><img src='/legacy-assets/whiteboard/uploadimage.png'></button>";
         html+= "<input type='file' class='image-file-upload' style='display:none'>";
      }
       
      if(options.teacher) {
         html+= "<button class='question-button'><img src='/legacy-assets/whiteboard/book.png' name='Load Question/Worksheet'></button>";
      }      
      html+= "<button class='text-button mode-button'><img src='/legacy-assets/whiteboard/text.png'></button>";
      html+= "<button class='equation-button mode-button' id='equation-button'><img src='/legacy-assets/whiteboard/textlatex.png'></button>";
      html+= "<button class='fullscreen-button'><img src='/legacy-assets/whiteboard/fullscreen.png' name='Fullscreen'></button>";
      if(options.allowColorInvert) {
         html+= "<button class='colorinvert-button mode-button'><img src='/legacy-assets/whiteboard/darkmode.png'></button>";
      }
      if(options.admin) {
         html+= "<button id='showadmin-button' class='admin-button'>::</button>";
         html+= "<button id='bounding-button' class='admin-button'>BoundingBox</button>";
         html+= "<button id='curve-button' class='admin-button'>BezierCurve</button>";
         html+= "<button id='load-button' class='admin-button'>Load</button>";
         html+= "<button id='save-button' class='admin-button'>Save</button>";
         html+= "<button id='saveas-button' class='admin-button'>Save As</button>";
         html+= "<button id='preview-button' class='admin-button'>Preview</button>";
         html+= "<span id='label' class='admin-button'></span>";
      }

      html+= "</div>";
      menu.html(html);
      if(options.admin) {
         $(".admin-button").hide();
         $("#showadmin-button").show();
      }
      $(".button-tab").hide();

      menu.find(".erase-menu").find('a').eq(0).mousedown(function(){ selectDrawMode(canvas, 'erase') });
      menu.find(".erase-menu").find('a').eq(1).mousedown(function(){ clearStrokes(canvas, 0) });
      menu.find(".erase-menu").find('a').eq(2).mousedown(function(){ clearStrokes(canvas, 1) });
      menu.find(".erase-menu").find('a').eq(3).mousedown(function(){ clearStrokes(canvas, 2) });
      menu.find(".grid-menu").find('a').eq(0).mousedown(function(){ selectGrid(canvas, 'none') });
      menu.find(".grid-menu").find('a').eq(1).mousedown(function(){ selectGrid(canvas, 'grid') });
      menu.find(".grid-menu").find('a').eq(2).mousedown(function(){ selectGrid(canvas, 'gridsmall') });
      menu.find(".grid-menu").find('a').eq(3).mousedown(function(){ selectGrid(canvas, 'dots') });
      menu.find(".grid-menu").find('a').eq(4).mousedown(function(){ selectGrid(canvas, 'isometric') });
      menu.find(".grid-menu").find('a').eq(5).mousedown(function(){ selectGrid(canvas, 'isometric2') });
      menu.find(".uploadimage-button").mousedown(function(){
         menu.find(".image-file-upload").trigger("click");	
      });
      menu.find(".image-file-upload").change(function(e){
         var files = e.target.files;
         handleWBPicUpload(files);
         $(".image-file-upload").val(""); // this is in case user wants to reupload same image after clearing whiteboard.
      });


      menu.find(".colours li a").mousedown(function(){
         control.currentColour = $(this).attr('rel');
         var c = $(this).attr('rel').substr(1);
         if(canvas.data('control').colorInvert && c=="000")c = "fff"; // use white instead of black in color invert mode.
         canvas.css('cursor', "url(/legacy-assets/whiteboard/cursor"+c+".cur) 6 6, auto");
         menu.find('.colours li a').removeClass('selected');
         $(this).addClass('selected'); 
         if(control.drawMode==="erase") {
            control.drawMode = menu.find(".drawmode-button img").attr('src')=="straightline.png" ? "straightline" : "pen";
         }
      });
      menu.find(".colour-default").mousedown();
      menu.find(".colorinvert-button").mousedown(function(){ invertWhiteboardColor(canvas) });
      menu.find(".undo-button").mousedown(function(){ undoLastStroke(canvas) });
      menu.find(".fullscreen-button").mousedown(toggleFullscreen);
      menu.find(".clear-button").mousedown(function(){ clearStrokes(canvas) });
      if(menu.find(".require-login").length>0)menu.find(".require-login").mousedown(changeRequireLogin);
      menu.find(".view-button").mousedown(function(){ setTab('view') });
      menu.find(".paint-button").mousedown(function(){ setTab('paint') });
      menu.find(".connect-button").mousedown(function(){ setTab('connect') });
      menu.find(".rubber-button").mousedown(function(){ wb.drawMode = "erase"; });
      menu.find(".pen-button").mousedown(function(){ selectDrawMode(canvas, 'pen'); });
      menu.find(".straightline-button").mousedown(function(){ selectDrawMode(canvas, 'straightline'); });
      menu.find(".circle-button").mousedown(function(){ selectDrawMode(canvas, 'circle'); });
      menu.find(".arc-button").mousedown(function(){ selectDrawMode(canvas, 'arc'); });
      menu.find(".text-button").mousedown(function(){ selectDrawMode(canvas, 'text'); });
      menu.find(".equation-button").mousedown(function(){ selectDrawMode(canvas, 'equation'); });

      $("#curve-button").mousedown(function(){ selectDrawMode(canvas, 'curve'); });
      $("#bounding-button").mousedown(function(){ selectDrawMode(canvas, 'bounding'); });
      $("#save-button").mousedown(function(){ save(false); });
      $("#saveas-button").mousedown(function(){ save(true); });
      $("#load-button").mousedown(function(){ load(canvas); });
      $("#preview-button").mousedown(function(){ preview(canvas); });
      menu.find("#showadmin-button").mousedown(function(){
         $(".admin-button").show();
         $(this).hide();
      });

      menu.find(".grid-button-super").mouseover(function(){
         menu.find(".grid-menu").toggle();
         var left = menu.find(".grid-button").offset().left;
         if(left + menu.find(".grid-menu").outerWidth() > $(document).width())left = $(document).width() - menu.find(".grid-menu").outerWidth();
         menu.find(".grid-menu").offset({left: left, top: menu.find(".grid-button").offset().top + menu.find(".grid-button").outerHeight() - 1 } );
      });
      menu.find(".grid-button-super").mouseout(function(){
         menu.find(".grid-menu").hide();
      });
      menu.find(".drawmode-button-super").mouseover(function(){
         menu.find(".drawmode-menu").toggle();
         menu.find(".drawmode-menu").offset({left: menu.find(".drawmode-button").offset().left, top: menu.find(".drawmode-button").offset().top + menu.find(".drawmode-button").outerHeight() - 1 } );
      });
      menu.find(".drawmode-button-super").mouseout(function(){   // delete: no longer used?
         menu.find(".drawmode-menu").hide();
      });
      menu.find(".erase-button-super").mouseover(function(){
         menu.find(".erase-menu").toggle();
         var left = menu.find(".erase-button").offset().left;
         if(left + menu.find(".erase-menu").outerWidth() > $(document).width())left = $(document).width() - menu.find(".erase-menu").outerWidth();
         menu.find(".erase-menu").offset({left: left, top: menu.find(".erase-button").offset().top + menu.find(".erase-button").outerHeight() - 1 } );
      });
      menu.find(".erase-button-super").mouseout(function(){
         menu.find(".erase-menu").hide();
      });

      menu.find(".question-button").mousedown(function(){ 
         if(!Context.user) {
            dfmAlert("You must be logged in with a teacher account in order to use this feature. <a href='/login.php?url=/whiteboard'>Click here to login.</a>");
         }  
         else selectQuestion(function(q){
            selectFuncCallback(q);
         })
      });
      if(arg1==="instantiateMenu")menu.find(".paint-button").click(); // only set to paint tab if instantiating, not reinstantiating

      if (document.addEventListener) {
         document.addEventListener('webkitfullscreenchange', exitHandler, false);
         document.addEventListener('mozfullscreenchange', exitHandler, false);
         document.addEventListener('fullscreenchange', exitHandler, false);
         document.addEventListener('MSFullscreenChange', exitHandler, false);
      }

      $(document).unbind('keydown'); 
      $(document).unbind('keypress'); 
      $(document).mousedown(function(event) {
         targetElement = event.target;
      });

      selectDrawMode(canvas, "pen");
   }
}
var targetElement;


function handleWBPicUpload(files) {
   var formData = new FormData();
   formData.append('file', files[0]);
    var jqXHR=$.ajax({
       url: "/util-uploadwhiteboardpic.php",
       type: "POST",
       contentType:false,
       dataType: "json",
       processData: false,
       cache: false,
       data: formData,
       success: function(data){
          if(data.img)setWhiteboardImg($("#whiteboard"), data.img, undefined, true);
          else dfmAlert("Error: <strong>"+data.error+"</strong>");
       },
       error: function(j, textStatus, errorThrown) {
          dfmAlert("There was an error: "+j.responseText+" "+errorThrown);
       }
    }); 
}


function invertWhiteboardColor(canvas) {
   var control = canvas.data("control");
   if(canvas.data('control').colorInvert) {
      $("html").removeClass('inverted');
      if(control.currentColour=="#000")canvas.css('cursor', "url(/legacy-assets/whiteboard/cursor000.cur) 6 6, auto");
   } else {
      $("html").addClass('inverted');
      if(control.currentColour=="#000")canvas.css('cursor', "url(/legacy-assets/whiteboard/cursorfff.cur) 6 6, auto");
   }
   canvas.data('control').colorInvert = !canvas.data('control').colorInvert;

   // If exam question image is loaded, use inverted version.
   var img = canvas.data('core').img;
   if(img && img.substr(0,12)=="/images/qimg") {
      if(canvas.data('control').colorInvert)setWhiteboardImg(canvas, img.replace(".png", "-inverted.png"), undefined, false);
      else setWhiteboardImg(canvas, img.replace("-inverted", ""), undefined, false);
   }

   drawWhiteboard(canvas);
}

// If a student whiteboard on teacher's whiteboard tab, don't want to invert colour, but reinvert for purposes of cursor colour and painted colours.
function invertSubWhiteboardColor(canvas) {
   var control = canvas.data("control");
   if(canvas.data('control').colorInvertSub) {
      if(control.currentColour=="#000")canvas.css('cursor', "url(/legacy-assets/whiteboard/cursor000.cur) 6 6, auto");
   } else {
      if(control.currentColour=="#000")canvas.css('cursor', "url(/legacy-assets/whiteboard/cursorfff.cur) 6 6, auto");
   }
   canvas.data('control').colorInvertSub = !canvas.data('control').colorInvertSub;
   drawWhiteboard(canvas);
}


const CROPEXTRA = 0.02;
function cropWhiteboard(canvas, wb) {
   var control = canvas.data("control");
   var ctx = canvas[0].getContext("2d");
   var minY = 10000000;
   var maxY = -1000000;
   var minX = 10000000;
   var maxX = -1000000;
   var fontSize = canvas.data("options").fixTextSize ? 30*control.zoom : Math.round(canvas.attr('width')*control.zoom / 40);
   var font = fontSize+"px Georgia";
   ctx.font = font;

   // console.log("[CropWhiteboard] "+JSON.stringify(wb));
   $.each(wb.strokes, function(k,s) {
      $.each(s.points, function(k2,p) {
         var textWidth = 0;
         var textHeight = 0;
         if(s.type==="text") {
            textWidth = ctx.measureText(s.text).width / canvas.attr('width');
            textHeight = 30*control.zoom / canvas.attr('width');
         }
         if(s.type==="equation") {
            textWidth = s.imgScale;
            textHeight = Math.max(0.1, s.imgScale * (1/2.5)); // Image not necessarily loaded yet, so just assume 1 2.5th of the width as the height, liberally.
         }
         if(p.y < minY)minY = p.y;
         if(p.x < minX)minX = p.x;
         if(p.y + textHeight > maxY)maxY = p.y + textHeight;
         if(p.x > maxX)maxX = p.x;
      });
   });
   console.log("MINY: "+minY+" MAXY: "+maxY);
   $.each(wb.strokes, function(k,s) {
      $.each(s.points, function(k2,p) {
         p.y -= minY - CROPEXTRA;
      });
   });

   // They might have exceed the default whiteboard width of '1' unit. Scale if this is the case.
   if(maxX > 1)$.each(wb.strokes, function(k,s) {
      $.each(s.points, function(k2,p) {
         p.x /= maxX;
         p.y /= maxX;
      });
   });


   return {whiteboard: wb, height: maxY - minY + CROPEXTRA };
}

function finishTextBox(canvas) {
   var control = canvas.data("control");
   control.currentTextBox.text = $("#dummy-text-input").val();
   $("#dummy-text-input").remove();
   if($.trim(control.currentTextBox.text)!="")canvas.data("core").strokes.push(control.currentTextBox);
   if(control.transmit)control.transmit("stroke", canvas, control.currentTextBox);
   control.currentTextBox = undefined;
   drawWhiteboard(canvas);
}

// Takes a 'stroke' representing a latex equation, and renders the image.
// If callback is set, it will be executed.
var equationImageId = 1;
function loadEquationStrokeImage(stroke, callback) {
   var equationImageIdFixed = equationImageId++;
   $('body').append("<span id='latex-to-imagify-"+equationImageIdFixed+"' style='visibility:hidden'></span>");
   let node = document.querySelector('#latex-to-imagify-'+equationImageIdFixed);
   let options = MathJax.getMetricsFor(node, true);
   let html = MathJax.tex2svg(stroke.latex ? stroke.latex : "", options);
   node.appendChild(html);
   $("#dummy-equation-input").remove();
   closeKeyboard(true);

   console.log("[GeneratingEquationImage] "+$("#latex-to-imagify-"+equationImageIdFixed).find('svg').length);
   var svg = $("#latex-to-imagify-"+equationImageIdFixed).find('svg')[0];
   var svgData = new XMLSerializer().serializeToString(svg);
   var svgSize = svg.getBoundingClientRect();
   var img = document.createElement("img");
   img.setAttribute("src", "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))));
   img.onload = function() {
      console.log("[EquationImageLoaded]");
      $("#latex-to-imagify-"+equationImageIdFixed).remove();
      stroke.imgLoaded = img;
      if(callback)callback();
   };
}

function finishEquationBox(canvas) {
   var control = canvas.data("control");
   var latex = $("#dummy-equation-input").algebraicInput("latex");
   console.log("[Latex] "+latex);
   control.currentEquationBox.latex = latex;

   var w = canvas.attr('width') * control.zoom; // get actual canvas width if extended to full viewport.
   console.log("[W] "+$("#dummy-equation-input").outerWidth()+" "+w);
   control.currentEquationBox.imgScale = $("#dummy-equation-input").outerWidth() / w;

   loadEquationStrokeImage(control.currentEquationBox, function(){
      console.log("[AddingEquationStroke] "+JSON.stringify(control.currentEquationBox));
      if($.trim(control.currentEquationBox.latex)!="")canvas.data("core").strokes.push(control.currentEquationBox);
      if(control.transmit)control.transmit("stroke", canvas, control.currentEquationBox);
      control.currentEquationBox = undefined;
      drawWhiteboard(canvas);
   });

}

function selectGrid(canvas, type) {
   console.log("Grid: "+type);
   if(canvas.data("core").currentTextBox)finishTextBox(canvas); 
   canvas.data("core").grid = type;
   canvas.data("menu").find(".grid-menu").hide();
   drawWhiteboard(canvas);
   if(canvas.data("control").transmit)canvas.data("control").transmit("grid", canvas, type);
}

var taughtArcMode = false;
function selectDrawMode(canvas, mode) {
   if(canvas.data("control").currentTextBox)finishTextBox(canvas); 
   $(".mode-button").removeClass('selected');

   var menu = canvas.data("menu");
   if(menu) {
      menu.find("."+mode+"-button").addClass('selected');
      menu.find("."+mode+"-button").blur();
   }

   canvas.data("control").drawMode = mode;
   var c = canvas.data("control").currentColour.substr(1);
   if(canvas.data('control').colorInvert && c=="000")c = "fff"; // use white instead of black in color invert mode.
   if(mode=="pen" || mode=="straightline" || mode=="circle" || mode=="arc" || mode=="curve")canvas.css('cursor', "url(/legacy-assets/whiteboard/cursor"+c+".cur) 6 6, auto");

   console.log("C: "+c);
   // if(mode!="erase")menu.find(".drawmode-button img").attr('src', mode+".png");
   if(mode==="erase")canvas.css('cursor', "url(/legacy-assets/whiteboard/cursor-erase.cur) 6 6, auto");

   menu.find(".drawmode-menu").hide();
   menu.find(".erase-menu").hide();
   if(mode==="arc" && !taughtArcMode) {
      taughtArcMode = true;
      dfmAlert("To draw an arc, click the centre first, then drag your mouse <strong>clockwise</strong> between the two ends of the arc.");
   }

   if(mode==="text" || mode==="equation")canvas.addClass('text');
   else canvas.removeClass('text');

   // If drawing a curve, finish.
   if(canvas.data("control").currentStroke && canvas.data("control").currentStroke.type == "curve") {
      console.log("[EndingCurve]");
      canvas.data("control").currentStroke.points.splice(-1)
      canvas.data("core").strokes.push(canvas.data("control").currentStroke);
      canvas.data("control").currentStroke = undefined;
      canvas.data("control").isStroking = false;
      drawWhiteboard(canvas);
   }

}


function getClosestPointOnLineSegment(p, p1, p2) {
  var x = p.x; var y = p.y;
  var x1 = p1.x; var y1 = p1.y;
  var x2 = p2.x; var y2 = p2.y;
  var A = x - x1;
  var B = y - y1;
  var C = x2 - x1;
  var D = y2 - y1;

  var dot = A * C + B * D;
  var len_sq = C * C + D * D;
  var param = -1;
  if (len_sq != 0) //in case of 0 length line
      param = dot / len_sq;
  var xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  }
  else if (param > 1) {
    xx = x2;
    yy = y2;
  }
  else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }
  var dx = x - xx;
  var dy = y - yy;
  return {x: xx, y: yy};
}


function deleteStrokesUnderMouse(canvas, e) {
   var p = calculatePoint(e, canvas, null, true);
   var toDelete = [];
   var whiteboard = canvas.data("core");
   var control = canvas.data("control");
   var toDeleteIds = [];
   var bestDist = 100000000000;

   for(var i=0; i<whiteboard.strokes.length; i++) {
      var sPoints = whiteboard.strokes[i].points;
      if(whiteboard.strokes[i].type==="circle") {
         var r = Math.sqrt(Math.pow(sPoints[0].x - sPoints[1].x,2) + Math.pow(sPoints[0].y - sPoints[1].y,2));
         var dist = Math.sqrt(Math.pow(sPoints[0].x - p.x,2) + Math.pow(sPoints[0].y - p.y,2));
         if(Math.abs(r-dist)<0.005) {
            toDelete.push(i);
            toDeleteIds.push(whiteboard.strokes[i].id);
         }
      } else if(whiteboard.strokes[i].type==="arc") {
         var arcCentre = whiteboard.strokes[i].arcCentre;
         var r = Math.sqrt(Math.pow(sPoints[0].x - arcCentre.x,2) + Math.pow(sPoints[0].y - arcCentre.y,2));
         var dist = Math.sqrt(Math.pow(arcCentre.x - p.x,2) + Math.pow(arcCentre.y - p.y,2));
         var angle = Math.atan2(p.y - arcCentre.y, p.x - arcCentre.x) * 180 / Math.PI;
         var angleL = Math.atan2(sPoints[0].y - arcCentre.y, sPoints[0].x - arcCentre.x) * 180 / Math.PI;
         var angleR = Math.atan2(sPoints[1].y - arcCentre.y, sPoints[1].x - arcCentre.x) * 180 / Math.PI;
         var inAngleRange = (angle >= angleL && angle<= angleR) || (angle >= angleL && angleR<0) || (angle <= angleR && angleL>0) || (angleL<0 && angleR<0 && (angle>=angleL || angle<=angleR));
         if(Math.abs(r-dist)<0.005 && inAngleRange) {
            toDelete.push(i);
            toDeleteIds.push(whiteboard.strokes[i].id);
         }
      } else if(whiteboard.strokes[i].type==="equation") {
         var minX = sPoints[0].x;
         var minY = sPoints[0].y;
         var maxX = sPoints[0].x + whiteboard.strokes[i].imgScale;
         var widthToHeightRatio = 1 / 2.5; // default;
         if(whiteboard.strokes[i].imgLoaded) {
            widthToHeightRatio = whiteboard.strokes[i].imgLoaded.naturalHeight / whiteboard.strokes[i].imgLoaded.naturalWidth;
         }
         maxY = sPoints[0].y + whiteboard.strokes[i].imgScale * widthToHeightRatio;
         if(p.x >= minX && p.x <= maxX && p.y >= minY && p.y <= maxY) {
            toDelete.push(i);
            toDeleteIds.push(whiteboard.strokes[i].id);
         }
         
      } else if(whiteboard.strokes[i].type==="text") {

         var fontSize = Math.round(whiteboard.width*control.zoom / 40);
         var font = fontSize+"px Georgia";
         var ctx = canvas[0].getContext("2d");
         var textWidth = ctx.measureText(whiteboard.strokes[i].text).width;
         var textHeight = 30*control.zoom; // getTextHeight(font).height;
         var sf = ($(window).width() / $(document).width()) * (1/whiteboard.width) * (1/control.zoom);
         var minX = sPoints[0].x - 5*sf;
         var maxX = sPoints[0].x + (textWidth+5)*sf;
         var minY = sPoints[0].y - ((textHeight+15)*sf);
         var maxY = sPoints[0].y + (15*sf);
         if(p.x >= minX && p.x <= maxX && p.y >= minY && p.y <= maxY) {
            toDelete.push(i);
            toDeleteIds.push(whiteboard.strokes[i].id);
         }
      } else {


         for(var j=0; j<sPoints.length-1; j++) {
            var dist = distanceFromPointToLineSegment(p, sPoints[j], sPoints[j+1]);
            /* if(dist<bestDist) {
               bestDist = dist;
               if(Context.user && Context.user.id==1)debugPoint = getClosestPointOnLineSegment(p, sPoints[j], sPoints[j+1]);
            } */
            if(dist<0.005) {
               toDelete.push(i);
               toDeleteIds.push(whiteboard.strokes[i].id);
               break;
            }
         }

      }
   }
   // if(Context.user && Context.user.id==1)drawWhiteboard(canvas);
   var newStrokes = [];

   for(var i=0; i<whiteboard.strokes.length; i++) {
      if($.inArray(i, toDelete)==-1)newStrokes.push(whiteboard.strokes[i]);
   }

   whiteboard.strokes = newStrokes;
   if(toDelete.length>0)drawWhiteboard(canvas);

   // Transmit
   if(canvas.data("control").transmit && toDelete.length>0) {
      for(var i=0; i<toDeleteIds.length; i++) {
         canvas.data("control").transmit("delete", canvas, toDeleteIds[i]);
      }
   }


}

var debugPoint = undefined;

function startDraw(canvas, e) {
   console.log("[StartDraw]");
   var control = canvas.data("control");
   var whiteboard = canvas.data("core");
   e.preventDefault ? e.preventDefault() : (e.returnValue = false); // ensures this works with IE/Edge
   control.isStroking = true;

   if(control.drawMode==="erase")return deleteStrokesUnderMouse(canvas, e);

   // May actually be a pan or zoom rather than drawing.
   if(e.touches && e.touches.length==2) {
      control.touchedStart1 = calculatePoint(e, canvas, 0, false);
      control.touchedStart2 = calculatePoint(e, canvas, 1, false);

      if(control.touchedStart1.x > control.touchedStart2.x) {
         var temp = control.touchedStart1;
         control.touchedStart1 = control.touchedStart2;
         control.touchedStart2 = temp;
      }

      control.touchPosStart = control.offset;
      control.touchZoomStart = control.zoom; 
      // if(Context.user && Context.user.id==1)$("#label").html("START "+JSON.stringify(control.touchedStart1)+" "+JSON.stringify(control.touchedStart2));
      return;
   }
   if(!control.currentStroke) {
      var type = "normal";
      if(control.drawMode==="circle" || control.drawMode==="arc" || control.drawMode==="bounding" || control.drawMode==="curve")type = control.drawMode;
      control.currentStroke = { color: control.currentColour, points: [], id: getStrokeRandomId(), type: type };
   }

   var pt = calculatePoint(e, canvas);
   if(!control.currentEquationBox && control.drawMode==="equation") {
      control.currentEquationBox = { type: "equation", points: [pt], latex: "", "color": control.currentColour, imgLoaded: null }
      $('body').append("<span id='dummy-equation-input' style='position:absolute;font-size:40px'></span>"); 
      var offset = e.touches ? {left: e.touches[0].clientX, top: e.touches[0].clientY} : {left: e.clientX, top: e.clientY};
      offset.top-= 0.5*$("#dummy-equation-input").outerHeight();
      $("#dummy-equation-input").offset(offset);
      $("#dummy-equation-input").algebraicInput("handlers", {
                enter: function(){ finishEquationBox(canvas); } });
      MathJax.typeset();
      $("#dummy-equation-input").algebraicInput("focus");
      setTimeout(function(){ showKeyboard($("#dummy-equation-input")); }, 500);
   } else if(control.currentEquationBox && control.drawMode==="equation") {
      finishEquationBox(canvas);
   } else if(!control.currentTextBox && control.drawMode==="text") {
      control.currentTextBox = { type: "text", points: [pt], text: "", "color": control.currentColour }
      $('body').append("<input id='dummy-text-input' style='position: absolute; z-index: 2;' type='text'>"); 
      var offset = e.touches ? {left: e.touches[0].clientX, top: e.touches[0].clientY} : {left: e.clientX, top: e.clientY};
      offset.top-= 0.5*$("#dummy-text-input").outerHeight();
      $("#dummy-text-input").offset(offset);

      $("#dummy-text-input").change(function(){
         finishTextBox(canvas);
      });

      $("#dummy-text-input").focus(); // forces onscreen keyboard on phones.
   } else if(control.currentTextBox && control.drawMode==="text") {
      // Have they clicked outside of textbox?
      var fontSize = Math.round(whiteboard.width*control.zoom / 40);
      var font = fontSize+"px Georgia";
      var ctx = canvas[0].getContext("2d");
      var textWidth = ctx.measureText(control.currentTextBox.text).width;
      var textHeight = 30*control.zoom; // getTextHeight(font).height;
      var sf = ($(window).width() / $(document).width()) * (1/whiteboard.width) * (1/control.zoom);
      var minX = control.currentTextBox.points[0].x - 5*sf;
      var maxX = control.currentTextBox.points[0].x + (textWidth+5)*sf;
      var minY = control.currentTextBox.points[0].y - ((textHeight+15)*sf);
      var maxY = control.currentTextBox.points[0].y + (15*sf);
      if(!(pt.x >= minX && pt.x <= maxX && pt.y >= minY && pt.y <= maxY))finishTextBox(canvas); 
   }

   if(control.drawMode!="arc" || control.currentStroke.arcCentre) {
      control.currentStroke.points.push(pt);
   }
   else {
      control.currentStroke.arcCentre = pt;
   }
   drawWhiteboard(canvas);
}

function undoLastStroke(canvas) {
   var whiteboard = canvas.data("core");
   whiteboard.strokes.pop();
   drawWhiteboard(canvas);
   if(canvas.data("control").transmit)canvas.data("control").transmit("delete", canvas);
}

export function clearStrokes(canvas, mode) {
   if(!mode)mode = 0;
   console.log("[ClearStrokes] "+canvas.attr('id')+" mode="+mode);
   var whiteboard = canvas.data("core");
   whiteboard.strokes = [];
   if(mode==2)whiteboard.teacherStrokes = [];
   // whiteboard.grid = "none";

   if(mode!=1 && (!canvas.data("wb") && !canvas.data("w") && (!Context.user || Context.user.type!="student"))) {
      whiteboard.img = undefined;
      whiteboard.imgLoaded = undefined;
   }

   drawWhiteboard(canvas);
   if(canvas.data("control").transmit)canvas.data("control").transmit("clear"+mode, canvas);

   $(".erase-menu").hide();
}


// i is the index of the touch point (if multiple touches)
function calculatePoint(e, canvas, i, adjustForZoom) {
   var t = canvas;
   var wb = canvas.data("core");
   var control = canvas.data("control");
   if(!i)i = 0;
   if(adjustForZoom!==false)adjustForZoom = true;
   var x; var y;
   if(e.touches) {
      x = (e.touches[i].clientX + $(window).scrollLeft() - t.offset().left - bWidth)/t.width();
      y = (e.touches[i].clientY + $(window).scrollTop() - t.offset().top - bWidth)/t.width(); // changed from t.height()
   } else {
      x = (e.clientX + $(window).scrollLeft() - t.offset().left - bWidth)/t.width();
      y = (e.clientY + $(window).scrollTop() - t.offset().top - bWidth)/t.width();
   }
   // Adjust for zooming
   if(adjustForZoom) {
      x = (x / control.zoom) + control.offset.x;
      y = (y / control.zoom) + control.offset.y;
   }
   x = Number(Number.parseFloat(x).toPrecision(7)); // round to 7sf
   y = Number(Number.parseFloat(y).toPrecision(7));

   return {x:x, y:y};
}

var counterA = 0;
function continueDraw(canvas, e) {
   // console.log("[ContinueDraw]");
   try {

   var control = canvas.data("control");
   var whiteboard = canvas.data("core");
   e.preventDefault();
   // if(Context.user && Context.user.id==1)$("#label").html("ContinueDraw "+JSON.stringify(control.touchedStart1));
   if(control.drawMode==="text" || control.drawMode==="equation")return;
   if(control.drawMode==="erase" && control.isStroking)return deleteStrokesUnderMouse(canvas, e);

   if(control.currentStroke && control.currentStroke.type==="arc" && control.currentStroke.arcCentre && control.currentStroke.points.length==0)return; // set arc centre but haven't yet begun drawing arc  

   if(control.touchedStart1) {
      // We're zooming/panning with fingers.
      var touchedCurrent1 = calculatePoint(e, canvas, 0, false);
      var touchedCurrent2 = calculatePoint(e, canvas, 1, false);

      if(touchedCurrent1.x > touchedCurrent2.x) {
         var temp = touchedCurrent1;
         touchedCurrent1 = touchedCurrent2;
         touchedCurrent2 = temp;
      }

      var zoomSF = Math.sqrt(Math.pow(touchedCurrent2.x - touchedCurrent1.x,2) + Math.pow(touchedCurrent2.y - touchedCurrent1.y,2)) 
             / Math.sqrt(Math.pow(control.touchedStart2.x - control.touchedStart1.x,2) + Math.pow(control.touchedStart2.y - control.touchedStart1.y,2));
      control.zoom = control.zoom * zoomSF;

      var oldOffsetX = control.offset.x;

      control.offset.x-= (touchedCurrent1.x - control.touchedStart1.x);
      control.offset.y-= (touchedCurrent1.y - control.touchedStart1.y);

      // if(Context.user && Context.user.id==1)$("#label").html("Z sf="+zoom	SF+" tS1="+control.touchedStart1.x+" tC1="+touchedCurrent1.x+" offB="+oldOffsetX+" offN="+control.offset.x);
      // if(Context.user && Context.user.id==1)$("#label").html("Z sf="+zoomSF+" tS1="+control.touchedStart1.x+" tC1="+touchedCurrent1.x+" tC2="+touchedCurrent2.x+" offB="+oldOffsetX+" offN="+control.offset.x);

      control.touchedStart1 = touchedCurrent1;
      control.touchedStart2 = touchedCurrent2;
      control.touchPosStart = control.offset;
      control.touchZoomStart = control.zoom;
      drawWhiteboard(canvas);
      return;
   }
   if(!control.isStroking)return;

   if(control.drawMode==="straightline" || control.drawMode==="circle" || control.drawMode==="arc" || control.drawMode==="text" || control.drawMode==="bounding")control.currentStroke.points.splice(1);
   else if(control.drawMode==="curve" && control.currentStroke.points.length>=2)control.currentStroke.points.splice(-1);
   control.currentStroke.points.push(calculatePoint(e, canvas)); 
   drawWhiteboard(canvas);

   } catch(err) {
      $("#label").html(err.message);
   }

}

function endDraw(canvas, e) {
   console.log("[EndDraw]");
   var control = canvas.data("control");
   var whiteboard = canvas.data("core");
   if(e)e.preventDefault();
   if(control.currentStroke && control.currentStroke.type==="curve") {
      // control.isStroking = false;
      return;
   }
   if(control.currentStroke && control.currentStroke.arcCentre && control.currentStroke.points.length==0) {
      console.log("Not ending arc draw");
      return; // only indicated arc centre - not done with drawing
   }
   if(control.touchedStart1) {
      control.touchedStart1 = null;
      control.touchedStart2 = null;
      control.touchZoomStart = null;
      control.touchPosStart = null;
      return;
   }
   control.isStroking = false;
   if(control.currentStroke) {
      if(control.currentStroke.type==="text")control.currentStroke.id = prompt("Enter id number.");  // remove this line? Never runs.
      if(e.touches && e.touches.length>=1)control.currentStroke.points.push(calculatePoint(e, canvas)); 
      if(control.currentStroke.points.length>1) {
         if(control.drawMode==="bounding") {
            whiteboard.bounding = control.currentStroke;
            console.log("[BoundingBox] "+JSON.stringify(whiteboard.bounding));
         }
         else whiteboard.strokes.push(control.currentStroke);
         // console.log("Finished stroke: "+JSON.stringify(control.currentStroke));
      }
   }
   var currentStroke = control.currentStroke;
   control.currentStroke = undefined;
   drawWhiteboard(canvas);

   if(canvas.data("control").transmit && control.drawMode!=="erase")canvas.data("control").transmit("stroke", canvas, currentStroke);
}

function getStrokeRandomId() {
   return ((Context.user && Context.user.id) ? Context.user.id+"-" : "") + Math.floor(Math.random()*10000000000000); 
}

function transmitWhiteboard(canvas) {
   console.log("THIS FUNCTION HAS BEEN DECOMMISIONED");
   return;

   if(!canvas.data("control").transmit)return;
   console.log("Transmitting");
   var whiteboard = canvas.data("core");
   // Compress coordinates for more efficient transmission.
   var whiteboardSend = { strokes: compressStrokes(whiteboard.strokes), 
         teacherStrokes: compressStrokes(whiteboard.teacherStrokes), width: whiteboard.width, height: whiteboard.height };
   if(whiteboard.img) {
      whiteboardSend.img = whiteboard.img;
      whiteboardSend.imgScale = whiteboard.imgScale;
   }
   if(whiteboard.grid)whiteboardSend.grid = whiteboard.grid;
   canvas.data("control").transmit(whiteboardSend);

}

function getCorrectSizeWithOffset(actualWidth, actualHeight, viewWidth, viewHeight) {
   var wSF1 = actualHeight / actualWidth;
   var wSF2 = viewHeight / viewWidth;
   // console.log("WSF1: "+wSF1+" WSF2: "+wSF2+" VIEWHEIGHT: "+viewHeight+" ACTUALHEIGHT: "+actualHeight+" VIEWWIDTH: "+viewWidth+" ACTUALWIDTH: "+actualWidth);
   var xOffset = 0;
   var yOffset = 0;
   if(wSF1 > wSF2) {
      // Need to pad out horizontally. (NEW: Don't for moment)
      actualHeight = viewHeight;
      actualWidth = viewHeight / wSF1;
      xOffset = 0; // (viewWidth - actualWidth) / 2;

   } else if(wSF1 < wSF2) {
      // Need to pad out top and bottom. (NEW: Don't pad at top)
      actualWidth = viewWidth;
      actualHeight = viewWidth * wSF1;

      yOffset = 0; // (viewHeight - actualHeight) / 2;
   }
   return {w: actualWidth, h: actualHeight, xOffset: xOffset, yOffset: yOffset } 
}

export function setWhiteboardImg(canvas, img, imgScale, alert, invert) {
   var wb = canvas.data("core");
   wb.img = img;
   var imgO = new Image();
   if(img) {
      imgO.onload = function () {
         wb.imgLoaded = imgO;
         if(imgScale)wb.imgScale = imgScale; // if image scale was specified (e.g. via received transmission)
         else if(imgO.naturalHeight / imgO.naturalWidth > wb.height / wb.width) {
            // Set width of image as percentage of canvas width, so that all image appears height-wise.
            wb.imgScale = wb.height / (wb.width * imgO.naturalHeight / imgO.naturalWidth);
         }
         else wb.imgScale = 1;
         wb.imgInvert = invert;
         drawWhiteboard(canvas);
         if(alert && canvas.data("control").transmit)canvas.data("control").transmit("image", canvas, {scale: wb.imgScale, img: img});
      }
      imgO.src = img;  
   }
}

export function setWhiteboardImgDesmos(canvas, imgData) {
   var wb = canvas.data("core");
   wb.img = undefined;
   wb.imgInvert = undefined;
   wb.imgScale = 1;
   var imgO = new Image();
   imgO.onload = function () {
      wb.imgLoaded = imgO;
      drawWhiteboard(canvas);
   }
   imgO.src = imgData;  
}

export function clearWhiteboardImg(canvas) {
   const wb = canvas.data("core");
   wb.img = undefined;
   wb.imgInvert = undefined;
   wb.imgLoaded = undefined;
   wb.imgScale = undefined;
   drawWhiteboard(canvas);
}


function drawWhiteboard(canvas) {
   // console.log("[Draw] "+canvas.attr('id'));
   var wb = canvas.data("core");
   var control = canvas.data("control");
   if(!control)control = {};
   if(!control.zoom)control.zoom = 1.0;
   if(!control.offset.x)control.offset.x = 0;
   if(!control.offset.y)control.offset.y = 0;

   var ctx = canvas[0].getContext("2d");
   var w = canvas.attr('width');
   var h = canvas.attr('height');
   var isInverted = canvas.data('control').colorInvert;
   ctx.fillStyle = isInverted ? "#000" : "#fff";
   ctx.fillRect(0, 0, w, h);
   ctx.lineCap = 'round';


   if(wb.bounding) {
      console.log("Drawing bounding box!");
      drawStroke(wb.bounding, canvas, w, h, control.zoom, control.offset.x, control.offset.y);
   }

   // drawGrid(wb.grid, canvas, wb.width, wb.height, control.zoom, control.offset.x, control.offset.y);
   drawGrid(wb.grid, canvas, w, h, control.zoom, control.offset.x, control.offset.y);
   if(wb.imgLoaded) {
      if(!control.zoom)control.zoom = 1;
      if(!control.offset.x)control.offset.x = 0;
      if(!control.offset.y)control.offset.y = 0;
      var i2 = getCorrectSizeWithOffset(w, h, w, h);
      ctx.drawImage(wb.imgLoaded, control.zoom*(-control.offset.x*i2.w + i2.xOffset), control.zoom*(-control.offset.y*i2.w + i2.yOffset), control.zoom * wb.imgScale * i2.w, control.zoom * wb.imgScale * i2.w * (wb.imgLoaded.naturalHeight / wb.imgLoaded.naturalWidth));
   }


   $.each(wb.teacherStrokes, function(k, v){
      // drawStroke(v, canvas, wb.width, wb.height, control.zoom, control.offset.x, control.offset.y);
      drawStroke(v, canvas, w, h, control.zoom, control.offset.x, control.offset.y);
   });
   $.each(wb.strokes, function(k, v){
      // drawStroke(v, canvas, wb.width, wb.height, control.zoom, control.offset.x, control.offset.y);
      drawStroke(v, canvas, w, h, control.zoom, control.offset.x, control.offset.y);
   });

   if(debugPoint) {
      var w = canvas.attr('width');
      var h = canvas.attr('height');
      var zoom = 1;
      var ctx = canvas[0].getContext("2d");
      ctx.strokeStyle = "#ff0";
      ctx.fillStyle = "#ff0";
      var xOffset = 0; var yOffset = 0;
      ctx.beginPath();
      ctx.arc(   zoom*((debugPoint.x)*w + xOffset),   zoom*((debugPoint.y)*w + yOffset), 5, 0, 2*Math.PI);
      ctx.stroke();
      ctx.fill();
   }

   if(control.currentStroke)drawStroke(control.currentStroke, canvas, w, h, control.zoom, control.offset.x, control.offset.y);
   if(control.currentTextBox)drawTextBox(control.currentTextBox, canvas, w, h, control.zoom, control.offset.x, control.offset.y, true);      
}

var gridWidth = 0.05;
var gridWidthSmall = 0.025

function drawGrid(type, canvas, width, height, zoom, posX, posY) {
   var w = canvas.attr('width');
   var h = canvas.attr('height');
   var isInverted = canvas.data('control').colorInvert || canvas.data('control').colorInvertSub;

   var ctx = canvas[0].getContext("2d");
   var dim = getCorrectSizeWithOffset(width, height, w, h);
   // console.log(id+" "+type+" ACTUALWIDTH: "+width+" ACTUALHEIGHT: "+height+" VIEWWIDTH: "+w+" VIEWHEIGHT: "+h+" DIM: "+JSON.stringify(dim)+" ZOOM: "+zoom);
   w = dim.w; h = dim.h;
   var xOffset = dim.xOffset; var yOffset = dim.yOffset;

   ctx.strokeStyle = isInverted ? "#aaa" : "#ddd";   // the eye can see shades of white/grey much better than shades of black
   ctx.fillStyle = isInverted ? "#aaa" : "#ddd";

   if(type=="grid" || type=="gridsmall") {
      ctx.lineWidth = 1;
      for(var x = 0; x <= 1; x+= (type=="grid" ? gridWidth : gridWidthSmall)) {       // vertical
         ctx.beginPath();
         ctx.moveTo(zoom*((x-posX) * w + xOffset), zoom*((0-posY)*w + yOffset));
         ctx.lineTo(zoom*((x-posX) * w + xOffset), zoom*(((h/w)-posY)*w + yOffset));
         ctx.stroke();
      }
      for(var y = 0; y <= h/w; y+= (type=="grid" ? gridWidth : gridWidthSmall)) {       // horizontal
         ctx.beginPath();
         ctx.moveTo(zoom*((0-posX)*w + xOffset), zoom*((y-posY)*w + yOffset));
         ctx.lineTo(zoom*((1-posX)*w + xOffset), zoom*((y-posY)*w + yOffset));
         ctx.stroke();
      }
   } else if(type=="dots") {
      ctx.lineWidth = 1;
      for(var x = 0; x <= 1; x+= gridWidth) {
         for(var y = 0; y <= 2; y+= gridWidth) {
            ctx.beginPath();
            ctx.arc(zoom*((x-posX)*w + xOffset), zoom*((y-posY)*w + yOffset), 3*(w/1400)*zoom, 0, 2*Math.PI);
            ctx.stroke();
            ctx.fill();
         }
      }
   } else if(type=="isometric") {
      ctx.lineWidth = 1;
      var yCounter = 0;
      for(var y = 0; y <= 2; y+= gridWidth * Math.sqrt(3)/2) {
         for(var x = 0; x <= 1; x+= gridWidth) {

            ctx.beginPath();
            ctx.arc(zoom*((x-posX)*w + xOffset + (yCounter%2==0 ? 0 : gridWidth*w/2)), zoom*((y-posY)*w + yOffset), 3*(w/1400)*zoom, 0, 2*Math.PI);
            ctx.stroke();
            ctx.fill();
         }
         yCounter++;
      }
   } else if(type=="isometric2") {
      ctx.lineWidth = 1;
      var yCounter = 0;
      for(var y = 0; y <= 2; y+= gridWidth*0.5) {
         for(var x = 0; x <= 1; x+= gridWidth * Math.sqrt(3)) {
            ctx.beginPath();
            ctx.arc(zoom*((x-posX)*w + xOffset + (yCounter%2==0 ? 0 : gridWidth*w*Math.sqrt(3)/2)), zoom*((y-posY)*w + yOffset), 3*(w/1400)*zoom, 0, 2*Math.PI);
            ctx.stroke();
            ctx.fill();
         }
         yCounter++;
      }

   }

}

function drawTextBox(textBox, canvas, width, height, zoom, posX, posY, hasBorder) {

   if(!zoom)zoom = 1;
   if(!posX)posX = 0;
   if(!posY)posY = 0;
   var w = canvas.attr('width');
   var h = canvas.attr('height');
   var isInverted = canvas.data('control').colorInvert || canvas.data('control').colorInvertSub;
   var ctx = canvas[0].getContext("2d");
   var dim = getCorrectSizeWithOffset(width, height, w, h);
   w = dim.w; h = dim.h;
   var xOffset = dim.xOffset; var yOffset = dim.yOffset;
   var fontSize = canvas.data("options").fixTextSize ? 30*zoom : Math.round(w*zoom / 40);
   // console.log("Text size: "+fontSize+" "+canvas.data("options").fixTextSize);
   var font = fontSize+"px Georgia";
   var textWidth = ctx.measureText(textBox.text).width;
   var textHeight = 30*zoom; // getTextHeight(font).height;
   var x = zoom * ((textBox.points[0].x - posX) * w + xOffset);
   var y = zoom * ((textBox.points[0].y - posY) * w + yOffset) + textHeight/2.0;
   // console.log(textWidth+" "+textHeight);

   // ctx.fillStyle = "#fff";
   // ctx.fillRect(x - 5, y - textHeight - 15, textWidth + 10, textHeight + 30);
   ctx.strokeStyle = isInverted && textBox.color=="#000" ? "#fff" : textBox.color; 
   ctx.fillStyle = isInverted && textBox.color=="#000" ? "#fff" : textBox.color;
   ctx.font = font;
   ctx.fillText(textBox.text, x, y);

/*
   if(hasBorder) {
      ctx.lineWidth = 1*zoom;
      ctx.strokeStyle = "#aaa"; 
      ctx.setLineDash([5*zoom,5*zoom]);
      ctx.strokeRect(x - 5, y - textHeight - 15, textWidth + 10, textHeight + 30);
      ctx.setLineDash([]);
   }
*/
}

var getTextHeight = function(font) {
  var text = $('<span>Hg</span>').css({ fontFamily: font });
  var block = $('<div style="display: inline-block; width: 1px; height: 0px;"></div>');
  var div = $('<div></div>');
  div.append(text, block);
  var body = $('body');
  body.append(div);
  try {
    var result = {};
    block.css({ verticalAlign: 'baseline' });
    result.ascent = block.offset().top - text.offset().top;
    block.css({ verticalAlign: 'bottom' });
    result.height = block.offset().top - text.offset().top;
    result.descent = result.height - result.ascent;
  } finally {
    div.remove();
  }
  return result;
};

function doColorInvert(color) {
   var newColor = "#";
   for(var i=1; i<=3; i++) {
      if(color.charAt(i)=="f")newColor+= "0";
      else if(color.charAt(i)=="e")newColor+= "1";
      else if(color.charAt(i)=="d")newColor+= "2";
      else if(color.charAt(i)=="c")newColor+= "3";
      else if(color.charAt(i)=="b")newColor+= "4";
      else if(color.charAt(i)=="a")newColor+= "5";
      else if(color.charAt(i)=="9")newColor+= "6";
      else if(color.charAt(i)=="8")newColor+= "7";
      else if(color.charAt(i)=="7")newColor+= "8";
      else if(color.charAt(i)=="6")newColor+= "9";
      else if(color.charAt(i)=="5")newColor+= "a";
      else if(color.charAt(i)=="4")newColor+= "b";
      else if(color.charAt(i)=="3")newColor+= "c";
      else if(color.charAt(i)=="2")newColor+= "d";
      else if(color.charAt(i)=="1")newColor+= "e";
      else if(color.charAt(i)=="0")newColor+= "f";
   }
   return newColor;
}

// width and height are of the source canvas, not the canvas being drawn into.
function drawStroke(stroke, id, width, height, zoom, posX, posY) {
   if(!id)id = "#whiteboard";
   if(!zoom)zoom = 1;
   if(!posX)posX = 0;
   if(!posY)posY = 0;
   var w = $(id).attr('width');
   var h = $(id).attr('height');
   if(!stroke || stroke.points.length==0)return;
   var p = stroke.points;
   var ctx = $(id)[0].getContext("2d");
   var dim = getCorrectSizeWithOffset(width, height, w, h);
   w = dim.w; h = dim.h;
   var xOffset = dim.xOffset; var yOffset = dim.yOffset;
   ctx.strokeStyle = (($(id).data('control').colorInvert||$(id).data('control').colorInvertSub) && stroke.color=="#000") ? doColorInvert(stroke.color) : stroke.color;

   var MAXWIDTHDIFF = 1;
   var lastWidth;
   if(stroke.type === "text" ) {
      drawTextBox(stroke, id, w, h, zoom, posX, posY, false); 
   }
   else if(stroke.type === "equation") {
      if(stroke.imgLoaded) {
         var i2 = getCorrectSizeWithOffset(w, h, w, h);
         var cX = zoom * ((p[0].x - posX) * w + xOffset);
         var cY = zoom*((p[0].y - posY ) * w + yOffset);
         if(!stroke.imgScale)stroke.imgScale = 1;
         try {   // try needed because imgLoaded value initially transmitted from another whiteboard won't actually be the image.
            ctx.drawImage(stroke.imgLoaded, cX, cY, zoom * stroke.imgScale * i2.w, zoom * stroke.imgScale * i2.w * (stroke.imgLoaded.naturalHeight / stroke.imgLoaded.naturalWidth));
         } catch(err) {}
      }
   }
   else {
      for(var i=1; i<p.length; i++) {
         var dist = Math.sqrt(Math.pow(p[i].x - p[i-1].x, 2) + Math.pow(p[i].y - p[i-1].y, 2));
         var lW = Math.max(3, Math.min(10, (Math.min(w/1400.0, 1))*Math.max(1, Math.pow(dist * 130, 1.4))) + 1);
         if(lastWidth) {
            if(lW > lastWidth + MAXWIDTHDIFF)lW = lastWidth + MAXWIDTHDIFF;
            if(lW < lastWidth - MAXWIDTHDIFF)lW = lastWidth - MAXWIDTHDIFF;
         }
         ctx.lineWidth = zoom * (p.length==2 ? 6 : lW); // for single straight line, use fixed width
         ctx.beginPath();

         if(stroke.type==="circle") {
            var cX = zoom * ((p[i-1].x - posX) * w + xOffset);
            var cY = zoom*((p[i-1].y - posY ) * w + yOffset);
            var pX = zoom * ((p[i].x - posX) * w + xOffset);
            var pY = zoom*((p[i].y - posY) * w + yOffset);
            var r = Math.sqrt(Math.pow(cX - pX,2) + Math.pow(cY - pY, 2));
            ctx.arc(cX, cY, r, 0, 2 * Math.PI);
         } else if(stroke.type==="arc") {
            var cX = zoom * ((stroke.arcCentre.x - posX) * w + xOffset);
            var cY = zoom* ((stroke.arcCentre.y - posY ) * w + yOffset);
            var p1X = zoom * ((p[i-1].x - posX) * w + xOffset);
            var p1Y = zoom*((p[i-1].y - posY ) * w + yOffset);
            var p2X = zoom * ((p[i].x - posX) * w + xOffset);
            var p2Y = zoom*((p[i].y - posY) * w + yOffset);
            var r = Math.sqrt(Math.pow(cX - p1X,2) + Math.pow(cY - p1Y, 2));
            var angle1 = Math.atan2(p1Y - cY, p1X - cX);
            var angle2 = Math.atan2(p2Y - cY, p2X - cX);
            ctx.arc(cX, cY, r, angle1, angle2);

         } else if(stroke.type==="bounding") {
            ctx.strokeStyle = "#eee";
            var p1X = zoom * ((p[i-1].x - posX) * w + xOffset);
            var p1Y = zoom*((p[i-1].y - posY ) * w + yOffset);
            var p2X = zoom * ((p[i].x - posX) * w + xOffset);
            var p2Y = zoom*((p[i].y - posY) * w + yOffset);
            ctx.rect(Math.min(p1X, p2X), Math.min(p1Y, p2Y), Math.abs(p1X - p2X), Math.abs(p1Y - p2Y));

         } else if(stroke.type!=="curve") {
            ctx.moveTo(zoom * ((p[i-1].x - posX) * w + xOffset), zoom*((p[i-1].y - posY ) * w + yOffset));
            ctx.lineTo(zoom * ((p[i].x - posX) * w + xOffset), zoom*((p[i].y - posY) * w + yOffset));
         }
         ctx.stroke();
         lastWidth = lW;
      }

      if(stroke.type==="curve") {
         console.log("[DrawingBezierCurve] "+p.length);
         var pT = [];
         ctx.lineWidth = 1;
         // var numPoints = p.length; var every = Math.floor(numPoints / 10);
         $.each(p, function(k,pt) {
            pT.push({x: zoom * ((pt.x - posX) * w + xOffset), y: zoom*((pt.y - posY) * w + yOffset)});
         });
         ctx.drawCurve(pT, 0.5, false, 100, false);
         ctx.stroke();
      }

   }

}


var fullscreen = false;
function toggleFullscreen() {
   if(!fullscreen) {
      openFullscreen();
   } else {
      closeFullscreen();
   }
   fullscreen = !fullscreen;
   $(window).resize();
}

function openFullscreen() {
  var elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE/Edge */
    document.msExitFullscreen();
  }
}

function exitHandler() {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        fullscreen = false;
    }
}


var alertedView2 = false;

function setTab(mode) {
   console.log("[SetTab] "+mode);
   $(".button-tab").removeClass('selected');
   $("."+mode+"-button").addClass('selected');
   $("."+mode+"-button").blur();
   if(mode==="paint") {
      $("#view-whiteboards").hide();
      $("#whiteboard").show();
      $("#connect-whiteboards").hide();      
   } else if(mode==="view") {
      $("#view-whiteboards").show();
      $("#whiteboard").hide();
      $("#connect-whiteboards").hide();

      if(alertedView && !alertedView2) {
         alertedView2 = true;
         const intro = introJs();
         intro.setOptions({
             showStepNumbers: false,
             showBullets: false,
             exitOnOverlayClick: true,
             steps: [{
                element: '#view-whiteboards ul',
                intro: "Clicking on a view will allow you to see it full-screen. Clicking again closes it.",
                position: 'top',
                highlightClass: 'menu-highlight'
                      
             }],
         });
         intro.start();
      }


   } else if(mode==="connect") {
      $("#connect-whiteboards").show();
      $("#whiteboard").hide();
      $("#view-whiteboards").hide();
   }
   $(window).resize();
}

var viewOpen = false;
function toggleView() {
   if(!viewOpen) {
      $("#view-whiteboards").show();
      $("#whiteboard").hide();
   } else {
      $("#view-whiteboards").hide();
      $("#whiteboard").show();
   }
   viewOpen = !viewOpen;
   $(window).resize();
}


$(window).resize(function(){ 
   // Temporary...

   $(".dfmWhiteboard").each(function() {
      if($(this).data('options').resize)resizeWhiteboardCanvas($(this)); 
   });
});


function resizeWhiteboardCanvas(canvas) {
   var whiteboard = canvas.data("core");
   canvas.attr('width', '1px');
   canvas.attr('height', '1px');
   $("#view-whiteboards > ul").outerHeight(1);
   var w = canvas.parent().width() - 3;
   var h = $(window).height() - 3 - $("#menu").outerHeight();
   // console.log($("#whiteboard").parent().height()+" "+$("#whiteboard").parent().outerHeight()+" "+$("#menu").outerHeight());

   w = 400; // for now
   h = $(window).height() - 6 - $("#doquestion-whiteboard-actionbutton").outerHeight();
   canvas.attr('width', w);
   canvas.css('width', w+"px");
   canvas.attr('height', h);
   canvas.css('height', h+"px");
   whiteboard.width = w;
   whiteboard.height = h;
   $("#view-whiteboards > ul").outerHeight(h - $("#connect-box").outerHeight() - 50);
   drawWhiteboard(canvas);

   if($("#view-whiteboards").find('.expandedView').length>0) {
      var user = $("#view-whiteboards").find('.expandedView').data('user');
      var uid = user.uid;
      var w = $(window).width() - 10;
      var h = $(window).height() - 50 - $("#view-"+uid+" h1").outerHeight();
      $("#view-"+uid+" canvas").attr('width', w);
      $("#view-"+uid+" canvas").css('width', w+"px");
      $("#view-"+uid+" canvas").attr('height', h);
      $("#view-"+uid+" canvas").css('height', h+"px");
      drawWhiteboard($("#whiteboard-"+user.uid));
   }
}


/* --------------------------------------------------------
   TRANSMISSION
   -------------------------------------------------------- */

// ---------------------------------------
// -- TO BE DELETED
export function compressStrokes(strokes) {
   if(!strokes)return [];
   var strokesNew = [];
   for(var i=0; i<strokes.length; i++) {
      var strokeNew = { color: strokes[i].color };
      strokeNew.points = compressCoordinates(strokes[i].points);
      if(strokes[i].type)strokeNew.type = strokes[i].type;          // does JavaScript having a JSON cloning mechanism?
      if(strokes[i].text)strokeNew.text = strokes[i].text;
      if(strokes[i].arcCentre)strokeNew.arcCentre = strokes[i].arcCentre;
      if(strokes[i].latex)strokeNew.latex = strokes[i].latex;
      if(strokes[i].imgScale)strokeNew.imgScale = strokes[i].imgScale;
      strokesNew.push( strokeNew );
   }  
   return strokesNew;
}

// ---------------------------------------
// -- TO BE DELETED

function uncompressStrokes(strokes) {
   if(!strokes)return strokes;
   for(var i=0; i<strokes.length; i++) {
      strokes[i].points = uncompressCoordinates(strokes[i].points);
   }  
   return strokes;
}

// ---------------------------------------
// -- TO BE DELETED

function compressCoordinates(coords) {
   var coordsNew = [];
   for(var i=0; i<coords.length; i++) {
      coordsNew.push(Number(coords[i].x).toFixed(5) + "," + Number(coords[i].y).toFixed(5) );
   }
   return coordsNew.join("|");
   // return coords.slice(0).map(function(c){ return c.x.toFixed(5)+","+c.y.toFixed(5) }).join("|");
}

// ---------------------------------------
// -- TO BE DELETED

function uncompressCoordinates(coordStr) {
   var s = coordStr.split("|");
   for(var i=0; i<s.length; i++)s[i] = {x: Number(s[i].split(",")[0]), y: Number(s[i].split(",")[1]) };
   return s;
   // return coordStr.split("|").map(c =>      {'x': c.split(",")[0], 'y': c.split(",")[1] }        );
}


/* --------------------------------------------------------
   QUESTION UNDERLAY SELECTION
   -------------------------------------------------------- */

var lastQuestionRecord;

// The process of browsing/selecting a question for the whiteboard.

function selectQuestion(callback) {
   $.ui.dialog.prototype._focusTable = function(){};
   var inputTxt = "<div>";
   inputTxt+= "<input type='hidden' id='worksheet-selector' value='1'> ";
   if(currentWorksheet)inputTxt+= "<a href='javascript:closeWorksheet()' class='dfm-directoryselector'>Close Worksheet</a>";
   if(!currentWorksheet) {
      inputTxt+= "<input type='hidden' name='browse-topics'>";
      inputTxt+= "<input type='hidden' id='browse-author'> ";
      inputTxt+= "<select name='browse-difficulty' style='padding:4px 8px 4px 8px;border-radius:5px;margin-left:10px'><option value=''>Difficulty: All</option><option value='1'>Difficulty: 1</option><option value='2'>Difficulty: 2</option><option value='3'>Difficulty: 3</option><option value='4'>Difficulty: 4</option></select>";
      // inputTxt+= " <select name='browse-author'><option value='0'>Author: Any</option><option value='6190'>Author: Edexcel</option><option value='83016'>Author: AQA</option><option value='84981'>Author: OCR</option><option value='1339'>Author: UKMT</option><option value='668592'>Author: Eduqas</option><option value='966092'>Author: WJEC</option><option value='142418'>Author: KS2/3 SATS</option><option value='-1'>User-contributed</option></select>";

      inputTxt+= " <input type='text' style='width:130px;margin-left:10px;background-color:#fff;border:1px solid rgb(169,169,169);border-radius:5px;padding:5px 8px 5px 8px' name='keywords' placeholder='filter by keywords'>";
   } else {
      inputTxt+= "<h2>"+currentWorksheetTitle+"</h2>";
   }
   inputTxt+= "</div>";
   inputTxt+= "<br><div id='question-selector'><ul></ul></div><div id='question-selector-display'></div>";

   dfmDialog(inputTxt, [{label: "Use this question", action: function(){
         if(!lastQuestionSelection) {
            dfmAlert("No question selected.");
            return;
         }
         callback(lastQuestionSelection);
         lastQuestionRecord = lastQuestionSelection; // remember for next pick.
         lastQuestionSelection = undefined;

   }}]);

   if(Context.user && Context.user.type=="parent")$("#question-selector-display").html("<p style='margin:20px;background-color:red;color:#fff;padding:10px;border-radius:5px'>Exam questions from exam boards (e.g. Edexcel, AQA) are only available to teacher accounts, due to license restrictions.</p>");

   $("#message-"+messageCounter).css('min-width', '800px');
   // $("#worksheet-selector").dfmDirectorySelector({});

   $("#worksheet-selector").dfmWorksheetSelector({callback: function(w){
      console.log("W: "+JSON.stringify(w));
      chooseWorksheet(w.id, w.title);
   }});
   $("#browse-author").dfmExamBoardSelector({callback: function(){
      loadQSummaries();
      lastAuthorSelection = $("#browse-author").val();
   }, light: true});
   $("#browse-author").dfmExamBoardSelector("val", "allexam");

   $("input[name=browse-topics]").dfmSkillDescriptorSelector({
      skillDescriptors: skillDescriptors,
      defaultMessage: "Filter by topic.",
      message: "Select a topic to filter by.",
      singleChoice: true,
      listener: function() {
         loadQSummaries(); 
         lastTopicSelection = $("input[name=browse-topics]").val();
         var selectedSkill = lastTopicSelection ? lastTopicSelection.substr(1) : undefined;
         $("#browse-author").dfmExamBoardSelector("setSkillFilter", selectedSkill);

      }
   });
   if(lastTopicSelection) {
      $("input[name=browse-topics]").dfmSkillDescriptorSelector("setValue", lastTopicSelection);
      $("#browse-author").dfmExamBoardSelector("setSkillFilter", lastTopicSelection.substr(1));
   }
   if(lastDifficultySelection)$("select[name=browse-difficulty]").val(lastDifficultySelection);
   if(lastAuthorSelection)$("#browse-author").dfmExamBoardSelector("val", lastAuthorSelection);
   if(lastKeywords)$("input[name=keywords]").val(lastKeywords);
   $("select[name=browse-difficulty]").change(function(){
      loadQSummaries(); 
      lastDifficultySelection = $(this).val();
   });
   $("select[name=browse-author]").change(function(){
      loadQSummaries(); 
      lastAuthorSelection = $(this).val();
   });

   $("input[name=browse-topics]").closest(".vex-content").css("width","750px");
   $("input[name=browse-topics]").next().find("span").css("display","inline-block");
   $("input[name=browse-topics]").next().find("span").css("font-size","16px");
   $("input[name=browse-topics]").next().css("display","inline-block");
   $("input[name=browse-topics]").next().css("width","auto");
   $("input[name=browse-topics]").next().css("vertical-align","middle");
   $("input[name=browse-topics]").next().css("margin-right","10px");
    
   // $("input[name=keywords]").dfmInputWithDefaultMessage("filter by text");
   $("input[name=keywords]").dfmWaitingInput(function(){
      loadQSummaries();
      lastKeywords = $("input[name=keywords]").val();
   });
   loadQSummaries();
   setTimeout(function(){ $("input[name=keywords]").blur() }, 30);

   
}

var lastTopicSelection;
var lastQuestionSelection;
var lastDifficultySelection;
var lastAuthorSelection;
var lastKeywords;

function loadQSummaries() {
   var browseTopics = $("input[name=browse-topics]").val();
   var browseDifficulty = $("select[name=browse-difficulty]").val();
   var browseKeywords = $("input[name=keywords]").dfmInputWithDefaultMessage("val");
   var browseAuthor = $("#browse-author").val();

   var params = [];
   // if(browseAuthor && browseAuthor!=0)params.push("user="+browseAuthor);
   if(browseKeywords)params.push("text="+browseKeywords);
   if(browseTopics)params.push("skills="+browseTopics);
   if(browseDifficulty)params.push("difficulty="+browseDifficulty);
   if(currentWorksheet)params.push("wid="+currentWorksheet);
   if(browseAuthor)params.push("courses="+$.map(browseAuthor.split(","), function(c) {return c.split("-").length==2 ? c.split("-")[1] : c}));

   var url = "/util-getmatchingquestions.php?"+params.join("&");
   console.log("URL: "+url);
   $.ajax({
      url: url,
      type: "GET",
      contentType:false,
      processData: false,
      cache: false,
      success: function(qsummaries){
         $("#num-questions").html(qsummaries.count);
         $("#question-selector ul").empty();
         $.each(qsummaries.summaries, function(k,summary) {
            var skillsTxt = [];
            $.each(summary.skills, function(k2,skill){
               skillsTxt.push(dfmSkillDescriptorSelector_findName(skill, skillDescriptors));
            });
            if(summary.permid)skillsTxt.push(dfmSkillDescriptorSelector_findName("k"+summary.permid, skillDescriptors, true));

            var summaryHTML = "<li  id='qinfo-"+$.escapeSelector("Q"+summary.id)+"' "+(false ? "class='question-used'" : "")+"><a href='#' "+(false ? "style='background-color: #ccc!important'" : "")+">";
            summaryHTML+= "<h1>"+replaceMathsTags(summary.content)+"</h1>";
            if(!summary.permid)summaryHTML+= "<h2><strong>Difficulty:</strong> <span class='difficulty-bar'>"+summary.difficulty+"</span></h2>";
            summaryHTML+= "<h2><strong>Author:</strong> "+summary.author+"</h2>";
            summaryHTML+= "<h2><strong>Skills:</strong> "+skillsTxt.join(" ")+"</h2>";
            summaryHTML+= "</a></li>";

            $("#question-selector ul").append(summaryHTML);
            $("#question-selector ul").children().last().data("summary", summary);
            $("#question-selector ul").children().last().click(function(){
               var s = $(this).data('summary');
               var url = summary.permid ? "/util-loadquestion.php?permid="+summary.permid+"&params="+encodeURIComponent(JSON.stringify(summary.params)) : "/util-loadquestion.php?qid="+summary.id;
               console.log(url);
               $.ajax({
                  url: url,
                  success: function(q){
                     q.wid = s.wid;
                     q.qnum = s.qnum;
                     dfm_generateQuestionContent($("#question-selector-display"), q, function(){ return false; });
                     $("#question-selector-display").find("input[type=submit]").hide();
                     MathJax.typeset();
                     lastQuestionSelection = q;
                  },
                  error: function(j, textStatus, errorThrown) {
                     dfmAlert("An error occurred: <strong>"+j.responseText+"</strong>");
                  }
               }); 
            });
         });
         if(qsummaries.count==0) {
            $("#question-selector ul").append("<li>Sorry, but there are no questions which match your criteria.</li>");
         }
         $(".difficulty-bar").each(function(){ $(this).dfmLevelBar(); });
         $(".difficulty-bar").dfmLevelBar("giveColour");
         MathJax.typeset();

         if(lastQuestionRecord) {
            console.log(JSON.stringify(lastQuestionRecord));
            var scrollTo = $("#qinfo-"+$.escapeSelector("Q"+lastQuestionRecord.id));
            var container = $("#question-selector");
            if(scrollTo.offset() && container.offset())setTimeout(function(){ 
               container.animate({
                  scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
            }) }, 300);

         }
      },
      error: function(j, textStatus, errorThrown) {
         dfmAlert("An error occurred: <strong>"+j.responseText+"</strong>");
      }
   }); 

}

var currentWorksheet = undefined;
function chooseWorksheet(wid, wtitle) {
   currentWorksheet = wid;
   currentWorksheetTitle = wtitle;
   
   selectQuestion(function(q){
      console.log("[SelectedQ] "+JSON.stringify(q));
      selectFuncCallback(q);
   });

}

function selectFuncCallback(q) {
   if(q.img) {
      var imgToUse = $("#whiteboard").data('control').colorInvert && q.invertimg ? q.invertimg : q.img;
      console.log("Img to use: "+imgToUse);
      setWhiteboardImg($("#whiteboard"), imgToUse, undefined, true);
   } else {
      setTimeout(function(){
         if(q.permid) {
            generateKeySkillImages(q, $("#whiteboard").data('control').colorInvert, function(imgToUse){
               console.log("Img to use: "+imgToUse);
               setWhiteboardImg($("#whiteboard"), imgToUse, undefined, true);
            });
         } else {
            dfmAlert("Sorry, but this question appears to not be generated as an image for use on the DFM Whiteboard.<br><br>If the question is from one your worksheets, open the worksheet from the Worksheets interface and resave it; this will cause image versions of any Key Skill questions to be regenerated.<br><br><strong>Note that you may need to wait up to 10 minutes before Key Skill questions saved in a worksheet become available in the DFM Whiteboard.</strong>");
         }
      }, 400);
   }

}

function closeWorksheet() {
   currentWorksheet = undefined;
   currentWorksheetTitle = undefined;
   $.modal.close();
   // REPEATED CODE - NEEDS REFACTORING
   selectQuestion(function(q){
      selectFuncCallback(q);
   });

}





// -----------------------------------------
// Curve drawing

function drawCurve(ctx, ptsa, tension, isClosed, numOfSegments, showPoints) {

    showPoints  = showPoints ? showPoints : false;

    ctx.beginPath();

    drawLines(ctx, getCurvePoints(ptsa, tension, isClosed, numOfSegments));

    if (showPoints) {
        ctx.stroke();
        ctx.beginPath();
        for(var i=0;i<ptsa.length-1;i+=2) 
                ctx.rect(ptsa[i] - 2, ptsa[i+1] - 2, 4, 4);
    }
}

function getCurvePoints(pts, tension, isClosed, numOfSegments) {

    // Convert from [{x1,y1},...] to [x1,y1,x2,...]
    var ptsE = [];
    for(var i=0; i<pts.length; i++) {
       ptsE.push(pts[i].x); ptsE.push(pts[i].y);
    }
    pts = ptsE;

    // use input value if provided, or use a default value   
    tension = (typeof tension != 'undefined') ? tension : 0.5;
    isClosed = isClosed ? isClosed : false;
    numOfSegments = numOfSegments ? numOfSegments : 16;

    var _pts = [], res = [],    // clone array
        x, y,           // our x,y coords
        t1x, t2x, t1y, t2y, // tension vectors
        c1, c2, c3, c4,     // cardinal points
        st, t, i;       // steps based on num. of segments

    // clone array so we don't change the original
    //
    _pts = pts.slice(0);

    // The algorithm require a previous and next point to the actual point array.
    // Check if we will draw closed or open curve.
    // If closed, copy end points to beginning and first points to end
    // If open, duplicate first points to befinning, end points to end
    if (isClosed) {
        _pts.unshift(pts[pts.length - 1]);
        _pts.unshift(pts[pts.length - 2]);
        _pts.unshift(pts[pts.length - 1]);
        _pts.unshift(pts[pts.length - 2]);
        _pts.push(pts[0]);
        _pts.push(pts[1]);
    }
    else {
        _pts.unshift(pts[1]);   //copy 1. point and insert at beginning
        _pts.unshift(pts[0]);
        _pts.push(pts[pts.length - 2]); //copy last point and append
        _pts.push(pts[pts.length - 1]);
    }

    // ok, lets start..

    // 1. loop goes through point array
    // 2. loop goes through each segment between the 2 pts + 1e point before and after
    for (i=2; i < (_pts.length - 4); i+=2) {
        for (t=0; t <= numOfSegments; t++) {

            // calc tension vectors
            t1x = (_pts[i+2] - _pts[i-2]) * tension;
            t2x = (_pts[i+4] - _pts[i]) * tension;

            t1y = (_pts[i+3] - _pts[i-1]) * tension;
            t2y = (_pts[i+5] - _pts[i+1]) * tension;

            // calc step
            st = t / numOfSegments;

            // calc cardinals
            c1 =   2 * Math.pow(st, 3)  - 3 * Math.pow(st, 2) + 1; 
            c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2); 
            c3 =       Math.pow(st, 3)  - 2 * Math.pow(st, 2) + st; 
            c4 =       Math.pow(st, 3)  -     Math.pow(st, 2);

            // calc x and y cords with common control vectors
            x = c1 * _pts[i]    + c2 * _pts[i+2] + c3 * t1x + c4 * t2x;
            y = c1 * _pts[i+1]  + c2 * _pts[i+3] + c3 * t1y + c4 * t2y;

            //store points in array
            res.push(x);
            res.push(y);

        }
    }

    return res;
}

function drawLines(ctx, pts) {
    ctx.moveTo(pts[0], pts[1]);
    for(i=2;i<pts.length-1;i+=2) ctx.lineTo(pts[i], pts[i+1]);
}

if (CanvasRenderingContext2D != 'undefined') {
    CanvasRenderingContext2D.prototype.drawCurve = 
        function(pts, tension, isClosed, numOfSegments, showPoints) {
       drawCurve(this, pts, tension, isClosed, numOfSegments, showPoints)}
}

function drawCurve2(points, tension, ctx) {
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);

    var t = (tension != null) ? tension : 1;
    for (var i = 0; i < points.length - 1; i++) {
        var p0 = (i > 0) ? points[i - 1] : points[0];
        var p1 = points[i];
        var p2 = points[i + 1];
        var p3 = (i != points.length - 2) ? points[i + 2] : p2;

        var cp1x = p1.x + (p2.x - p0.x) / 6 * t;
        var cp1y = p1.y + (p2.y - p0.y) / 6 * t;

        var cp2x = p2.x - (p3.x - p1.x) / 6 * t;
        var cp2y = p2.y - (p3.y - p1.y) / 6 * t;

        ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2.x, p2.y);
    }
    ctx.stroke();
}