import { posthogCaptureWithUserContext } from "./general";

/**
 * Captures the event the user turns on the anonymise mode from
 * the settings page.
 */
export function posthogEventAnonymiseModeTurnedOn() {
  posthogCaptureWithUserContext("anonymise_mode_turned_on");
}

/**
 * Captures the event the user clicks on a user result after using
 * the top search bar.
 */
export function posthogEventSearchUserClicked() {
  posthogCaptureWithUserContext("search_user_clicked");
}

/**
 * Captures the event the user chooses the move class option from
 * the user search result modal.
 */
export function posthogEventSearchUserMoveClassChosen() {
  posthogCaptureWithUserContext("search_user_move_class_chosen");
}

/**
 * Captures the event the user chooses the edit details option from
 * the user search result modal.
 */
export function posthogEventSearchUserEditDetailsChosen() {
  posthogCaptureWithUserContext("search_user_edit_details_chosen");
}

/**
 * Captures the event the user submits the edit details form on the
 * user search result modal.
 */
export function posthogEventSearchUserEditDetailsSubmitted() {
  posthogCaptureWithUserContext("search_user_edit_details_submitted");
}
